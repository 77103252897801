import { Container as MapDiv, NaverMap, Marker, useNavermaps } from 'react-naver-maps'


const MyMap = ({isSelected, officesInfo}) => {
  // instead of window.naver.maps
  const navermaps = useNavermaps()
  const FocusinMap = isSelected === null ? 0 : isSelected
  const namelatlonData = officesInfo.map((current) => [...[current.name, current.latitude, current.longitude]])
  console.log(namelatlonData)
  console.log(isSelected)
  return (
    <MapDiv
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <NaverMap
        center={new navermaps.LatLng(namelatlonData[FocusinMap][1], namelatlonData[FocusinMap][2])}
        defaultZoom={15}
      >
        {namelatlonData &&
          namelatlonData.map((current, index)=>
            <Marker
              key={index}
              title={current[0]}
              defaultPosition={new navermaps.LatLng(current[1], current[2])}
            />
          )}
      </NaverMap>
    </MapDiv>
  )
}

export default MyMap