import React, {useEffect, useState} from 'react'
import {
  AlertinMobileContainer,
  AlertinMobileWrapper,
  AlertinMobileMessage,
  CheckBtnWrapper,
  ShareButton,
  CloseButton    
}from './AlertInMobileElements'
import QuestionMarkUrl from '../../Asset/Icons/QuestionMark.png'
import { useHistory } from 'react-router-dom';
import axios from "axios";
import InfillPopUpUrl from "../../images/채움정도 팝업창.png"
import PQPopUpUrl from "../../images/프린트퀄리티 팝업창.png"
import LoadingGIF from '../LoadingAnimation/index'


const AlertinMobile = (props) => {
  const onClose = props.CloseModal


  const ShareHandle = () => {
    if (navigator.share) {
        navigator.share({
            title: 'TookDak',
            text: 'TookDak',
            url: 'https://www.tookdak.com',
        });
    }else{
        alert("공유하기가 지원되지 않는 환경 입니다.")
    }
  }
  
  

  return (
    <>
    <AlertinMobileContainer>
      <AlertinMobileWrapper>
        <AlertinMobileMessage>
          현재 저희 TookDak 은 컴퓨터 환경에 최적화 되어있습니다.<br/>
          모바일 환경 최적화도 진행중이니 많은 기대 부탁드립니다.<br/>
          저희 주소는 www.tookdak.com 입니다.<br/>
          감사합니다.
        </AlertinMobileMessage>
      </AlertinMobileWrapper>
      <CheckBtnWrapper>
        <ShareButton onClick = {ShareHandle}>공유하기</ShareButton>
        <CloseButton onClick={onClose}>닫기</CloseButton>
      </CheckBtnWrapper>
    </AlertinMobileContainer>
    </>
  );
};

export default AlertinMobile