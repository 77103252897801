import React from 'react'
import { useState } from "react";
import {
    OfficeInfoContainer,
    OffiecNum,
    OfficeNameAndAddress,
    OfficeName,
    OfficeAddress,
    OfficeWaitTime
} from "./OfficeComponentElements"

const OfficeComponent = ({
    isSelected,
    selectOffice,
    available,
    isShippingPossible,
    address,
    name,
    num,
    predictHour,
    predictMinute,
    predictSecond,
    waitJobAmount
}) => {
    const [isWatingTime, setIsWatingTime] = useState(true)

    if((predictHour + predictMinute + predictSecond) === 0){
        setIsWatingTime(false)
    }

// 배송 가능 불가능에 따라, 현재 작업 가능 불가능에 따라도 구분해줘야한다.
// 나중에 officeInfoContainer에 스크롤도 필요할 듯

  return (
    <OfficeInfoContainer 
        className={
            available ? 
                isSelected ?
                    "Clicked"
                :
                    ""
            : "notAvailable"}
        onClick={() => selectOffice(num)}>
        <OffiecNum>{num+1}</OffiecNum>
        <OfficeNameAndAddress>
            <OfficeName>
                {name}
            </OfficeName>
            <OfficeAddress>
                {address}
            </OfficeAddress>   
        </OfficeNameAndAddress>
        {isWatingTime === false ?
                <div>대기시간 : 없음</div>
                :
                <OfficeWaitTime
                    available={available}
                    isShippingPossible={isShippingPossible}
                    clicked={isSelected}>
                    {predictHour > 23 ? 
                    
                            `대기시간 : ${Math.floor(predictHour/24)}일 ${predictHour%24}시간 ${predictMinute}분`
                    
                        :
                            `대기시간 : ${predictHour}시간 ${predictMinute}분`
                    }
                </OfficeWaitTime>

        }
    </OfficeInfoContainer>
  )
}

export default OfficeComponent