import React from 'react'
import DaumPostcode from "react-daum-postcode";

const PostcodeModal = (props) => {
    // 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
    const handlePostCode = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 
        
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
          }
          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        console.log(data)
        console.log(fullAddress)
        console.log(data.zonecode)
        props.setPostCode(data.zonecode)
        props.setBasicAddress(fullAddress)
        props.onClose()
    }
 
    const postCodeStyle = {
        display: "block",
        position: "absolute",
        top : "40px",
        width: "100%",
        height: "90%"
      };
  return (
        <div>
            <DaumPostcode style={postCodeStyle} onComplete={handlePostCode} />
        </div>  )
}

export default PostcodeModal