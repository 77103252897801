import React, {useEffect, useState} from "react";
import {FaBars} from 'react-icons/fa';
import { IconContext } from "react-icons/lib";
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
import {
    Nav,
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavBtn,
    NavBtnLink,
    UserPageIcon
} from './NavbarElements';
import UserPageIconUrl from "../../images/user page.png"
const Navbar = ({toggle}) => {//create navigation bar
    const location = useLocation();
    const checkPath = ["/printsetting", "/login", "/mypage"]
    const isCheckPath = checkPath.some(path => location.pathname.includes(path));

    const [scrollNav, setScrollNav] = useState(false);
    const changeNav = () => {
        if(window.scrollY >= 30){
            setScrollNav(true);
        }else{
            setScrollNav(false);
        }
    };

    useEffect(()=>{
        window.addEventListener('scroll', changeNav)
    },[]);

    const toggleHome = () =>{
        scroll.scrollToTop();
    }

    return(
        <>
        <IconContext.Provider value={{color: '#fff'}}>
                <Nav className={isCheckPath ? "Maintenance" : ""} scrollNav={scrollNav}>
                    <NavbarContainer>
                        <NavLogo 
                            to = "/" 
                            onClick={toggleHome} 
                            scrollNav = {scrollNav}
                            className={isCheckPath ? "Maintenance" : ""}>ZADU</NavLogo>
                        {/* <MobileIcon onClick={toggle}>
                            <FaBars/>
                        </MobileIcon> */}
                        {/* <NavMenu>
                            <NavItem>
                                <NavLinks 
                                to = "about"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                                className={isCheckPath ? "Maintenance" : ""}
                                scrollNav = {scrollNav}
                                >
                                    About
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to = "Q&A"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}
                                    className={isCheckPath ? "Maintenance" : ""}
                                    scrollNav = {scrollNav}
                                    >
                                        Q&A
                                    </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to = "services"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}
                                    className={isCheckPath ? "Maintenance" : ""}
                                    scrollNav = {scrollNav}>
                                        Services
                                    </NavLinks>
                            </NavItem>
                        </NavMenu>*/}
                        <NavBtn
                            to = {sessionStorage.getItem("User_Num") != null ? "mypage" : "login"}>
                            <UserPageIcon src = {UserPageIconUrl}/>
                        </NavBtn>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
};
//<IconContext.Provider/>, scrollNav -> Writing for Changing Navbar background on scroll
export default Navbar;