import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    background: ${({ scrollNav }) => (scrollNav ? '#000' : 'transparent')};
    height : 80px;
    min-width: 1400px;
    margin-top : -80px;
    display : flex;
    justify-content: center;
    align-items: center;
    font-size : 1rem;
    position : sticky;
    top : 0;
    z-index:10;

    @media screen and (max-width: 960px){ 
        transition: 0.8s all ease;
    }

    &.Maintenance{
        background: #000;
    }
`

export const NavbarContainer = styled.div`
    display : flex;
    justify-content : space-between;
    height : 80px;
    z-index : 1;
    width : 100%;
    padding : 0 24px;
    max-width : 1100px;
`
export const NavLogo = styled(LinkR)`
    color : ${({ scrollNav }) => (scrollNav ? '#fff' : '#000')};
    justify-self : flex-start;
    cursor : pointer;
    font-size : 2.0rem;
    display : flex;
    align-items : center;
    font-weight : bold;
    text-decoration : none;

    
    &.Maintenance{
        color: #fff;
    }
`

export const MobileIcon = styled.div`
    display : none;

    @media screen and (max-width : 768px){
        display : block;
        color : #fff;
        position : absolute;
        top : 0;
        right : 0;
        transform : translate(-100%, 60%);
        font-size : 1.8rem;
        cursor : pointer;
    }
`

export const NavMenu = styled.ul`
    display : flex;
    align-items: center;
    list-style : none;
    text-align : center;
    margin-left : -50px;
    width: 400px;
    justify-content: space-between;

    @media screen and (max-width : 768px){
        display : none;
    }
`

export const NavItem = styled.li`
    height : 80px;
`

export const NavLinks = styled(LinkS)`
    color :  ${({ scrollNav }) => (scrollNav ? '#fff' : '#000')};
    display: flex;
    align-items: center;
    text-decoration : none;
    padding : 0 1rem;
    height : 100%;
    font-size: large;
    cursor : pointer;
    font-weight : bold;
    margin-top : 0;

    &:hover{
        border-bottom : 3px solid orange;
    }

    &.Maintenance{
        color: #fff;
    }
`
export const NavBtn = styled(LinkR)`
    display : flex;
    align-items : center;

    /* @media screen and (max-width: 768px){
        display : none;
    } */
`
export const NavBtnLink = styled(LinkR)`
    border-radius : 50px;
    background: #fa7f25;
    white-space : nowarp;
    padding: 10px 22px;
    color : #010606;
    font-size : 16px;
    outline : none;
    border : none;
    cursor : pointer;
    transition: all 0.2s ease-in-out;
    text-decoration : none;

    &:hover{
        transition : all 0.2s ease-in-out;
        background : #dfe8eb;
        color : #010606;
    }
`

export const UserPageIcon = styled.img`

`;