import styled from "styled-components";

export const Container = styled.div`
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items : center;
    width: 100%;
    min-width: 1400px;
    padding : 0 30px;
    padding-top: 100px;
    height : fit-content;
    position : relative;
    z-index : 1;

    padding-bottom: 50px;

    @media screen and (max-width : 768px){
        flex-direction: column;
        height: fit-content;
    }

`;
export const PaymentInfoContainer = styled.div`

    display: flex;
    flex-direction : column;
    justify-content: left;
    align-items : center;
    width: 1044px;
    height : 100%;
    position : relative;
    z-index : 1;

    @media screen and (max-width : 768px){
        flex-direction: column;
        height: fit-content;
    }

`;

export const PaymentTitleWrapper = styled.div`
    display : flex;
    position : relative;
    box-sizing: border-box;
    width : 100%;
    min-width: 400px;
    flex-direction: row;
    justify-content: space-between;

`;

export const PaymentInfoTitle = styled.div`
    position : relative;

    width : fit-content;
    height : fit-content;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
`;

export const Filename = styled.div`
    display : flex;
    position : relative;
    align-items: center;

    width : fit-content;
    height : 100%;
    
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #939393;

    padding-top: 5px;
`;

export const DividingLine1 = styled.div`
    width : 110%;
    min-width: 400px;

    height : 1px;

    background-color: #CCCCCC;

    margin-top: 10px;
`;

export const PaymentInfoInputWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: column;
 
    width: 100%;
    margin-top: 30px;
`;

export const NameInputwrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: column;
`;

export const NameInputTitle = styled.div`
    width: fit-content;
    height: fit-content;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;

`;

export const NameInput = styled.input`
    box-sizing: border-box;

    width : 50%;
    min-width : 200px;
    height : 40px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 7px;

    margin-top: 10px;
    padding-left: 5px;
`;

export const PhoneInputwrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: column;

    margin-top: 30px;
`;

export const PhoneInputTitle = styled.div`
    width: fit-content;
    height: fit-content;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
`;

export const PhoneInput = styled.input`
    box-sizing: border-box;

    width : 50%;
    min-width : 200px;

    height : 40px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 7px;

    margin-top: 10px;
    padding-left: 5px;

`;

export const EmailInputwrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: column;

    margin-top: 30px;
`;

export const EmailInputTitle = styled.div`
    width: fit-content;
    height: fit-content;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
`;

export const EmailInput = styled.input`
    box-sizing: border-box;

    width : 50%;
    min-width : 200px;

    height : 40px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 7px;

    margin-top: 10px;
    padding-left: 5px;

`;
export const ReceivemethodInputwrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: column;

    margin-top: 30px;
`;

export const ReceivemethodTitle = styled.div`
    width: fit-content;
    height: fit-content;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
`;

export const ReceivemethodBtnWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    /* @media screen and (max-width : 768px){
        flex-direction: column;
    } */
`;

export const DeliveryBtn = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width : 50%;
    min-width : 200px;

    height : 40px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 7px 0px 0px 7px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #BEBEBE;

    cursor: pointer;

    &.active{
        border: 2px solid #000000;
        color : #000000;
    }

    &:hover{
        border: 2px solid #000000;
        color : #000000;
    }

    @media screen and (max-width : 768px){
        border-radius: 7px
    }
`;

export const VisitedBtn = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width : 50%;
    min-width : 200px;

    height : 40px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 0px 7px 7px 0px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #BEBEBE;

    cursor: pointer;

    &.active{
        border: 2px solid #000000;
        color : #000000;
    }

    &:hover{
        border: 2px solid #000000;
        color : #000000;
    }
    @media screen and (max-width : 768px){
        border-radius: 7px
    }
`;
export const AddressInputwrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: column;

`;

export const AddressInputTitle = styled.div`
    width: fit-content;
    height: fit-content;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    
    margin-top: 30px;
`;

export const PostcodeInputWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: row;

    margin-top: 10px;

`

export const PostcodeInput = styled.input`
    box-sizing: border-box;

    width : 35%;
    height : 40px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 7px;

    padding-left : 10px;

    ::placeholder {
        color: #BBBBBB;
    }
`;

export const PostcodeSearchBtn = styled.div`
    position: relative;
    display : flex;
    justify-content: center;
    align-items: center;

    width : 80px;
    height: 40px;

    background: #000000;
    border-radius: 7px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;

    color : #FFFFFF;

    margin-left: 20px;

    cursor: pointer;
`

export const BasicAddressInput = styled.input`
    box-sizing: border-box;

    width : 100%;
    height : 40px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 7px;

    padding-left : 10px;
    margin-top: 10px;


    ::placeholder {
        color: #BBBBBB;
    }
`;

export const DetailAddressInput = styled.input`
    box-sizing: border-box;

    width : 100%;
    height : 35px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 7px;

    padding-left : 10px;
    margin-top: 10px;

    ::placeholder {
        color: #BBBBBB;
    }
`;

export const SelectOfficeWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: column;

    margin-top: 30px;


`;

export const SelectOfficeTitle = styled.div`
    width: fit-content;
    height: fit-content;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;

`;

export const SelectOfficeListandMap = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr; // 두 열이 각각 화면의 반을 차지하도록 설정
    gap: 5px;

    width : 100%;
    min-width: 400px;
    height : fit-content;

    justify-content: space-between;


    @media screen and (max-width : 1450px){
        grid-template-columns: 1fr; // 화면이 작아질 경우에는 한 열로 배치
        align-items: center;
    }
`;

export const SelectOfficeList = styled.div`
    width: 100%;
    height : fit-content;
`;

export const ReceivemethodMap = styled.div`
    position: relative;
    min-width : 250px;
    max-width: 450px;
    height : 300px;

    margin-top : 10px;
`;
export const DividingLine2 = styled.div`
    position: relative;
    display : flex;
    align-self: center;
    width : 110%;
    max-width: 1044px;
    min-width: 400px;

    height : 1px;

    background-color: #CCCCCC;

    margin-top: 30px;
`;
export const PaymentTypeAndPriceWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-top: 30px;

    @media screen and (max-width : 1450px){
        flex-direction: column;
        align-items: center;
    }
`;

export const PaymentTypeWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: column;

    width : fit-content;
`;

export const PaymentTypeTitle = styled.div`
    width: fit-content;
    height: fit-content;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
`;

export const PaymentTypeBtnWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: row;

    width : 437px;

    margin-top: 10px;

`;

export const CreditCard = styled.div`
    position: relative;
    display : flex;
    justify-content: center;
    align-items: center;

    width: 215px;
    height : 140px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    color : #bebebe;
    
    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 7px;

    cursor: pointer;

    &.active{
        border: 2px solid #000000;
        color : #000000;
    }

    &:hover{
        border: 2px solid #000000;
        color : #000000;
    }
`;
export const PaymentDescWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    margin-top: 15px;

`

export const QuestionMark = styled.img`
    width : 15px;
    height : 15px;

    margin-right : 5px;
`;

export const PaymentDesc = styled.div`
    color: #FA7225;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
`

export const PriceWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: column;
    width: 428px;
    min-width: 300px;
    height: fit-content;
    
    padding-top: 35px;
    padding-right: 36px;
`;

export const PrintPriceWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    width : 100%;
`;

export const PrintPriceTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color : #000000;
`;

export const PrintPrice = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color : #000000;
`;

export const ShippingfeeWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;

    margin-top: 10px;
`;

export const ShippingfeeTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color : #000000;
`;

export const Shippingfee = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color : #000000;
`;

export const DetailDevidingLine = styled.div`
    position: relative;
    display : flex;
    align-self: center;
    width : 110%;
    height : 1px;

    background-color: #CCCCCC;

    margin-top: 20px;
`;

export const TotalPriceWrapper = styled.div`
    position: relative;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;

    margin-top: 10px;

`;

export const TotalPriceTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    color : #000000;
`;

export const TotalPrice = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    color : #000000;
`;

export const DividingLine3 = styled.div`
    position: relative;
    display : flex;
    align-self: center;
    width : 110%;
    min-width: 400px;
    max-width: 1044px;

    height : 1px;

    background-color: #CCCCCC;

    margin-top: 65px;
`;

export const PaymentConsentCheckWrapper = styled.div`
    width: 100%;
    min-width: 400px;
    height: fit-content;
    margin-top: 40px;


`
export const PaymentAllConsentWrpper = styled.div`
    display: flex;
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

`

export const PaymentConsentCheckLable = styled.div`
    display: flex;
    flex-direction: row;
    align-items : center;
    width: 100%;
    height : fit-content;

    color: #5C5C5C;

    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;

    &.allConsent{
        color: #000;
        font-family: Roboto;
        font-size: 17px;
        font-style: normal;
        font-weight: 800;
        line-height: 140.625%;
    }
`

export const PaymentConsentCheckDesc = styled.div`
    margin-left: 30px;
`
export const IsExpanded = styled.div`
width: 13px;
height: 13px;

transform: rotate(45deg);

border-right: 3px solid #000;
border-bottom: 3px solid #000;

border-left: none;
border-top: none;

&.expanded{
    border-left: 3px solid #000;
    border-top: 3px solid #000;

    border-right: none;
    border-bottom: none;
}

`

export const PaymentConsentCheck = styled.input`
    position: absolute;

    width: 21px;
    height: 21px;

    border-radius: 5px;
    border: 1px solid #BBB;
    background: #F2F2F2;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);

    accent-color: #000;


    margin-right: 10px;
    margin-top : 2px;
`

export const DetailConsentCheckWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap : 8px;

    margin-top: 30px;
    margin-left: 30px;

`

export const DetailConsentCheckBox = styled.div`


`

export const FinalPaymentBtn = styled.div`
    position: relative;
    display : flex;
    justify-content: center;
    align-items: center;
    
    width : 100%;
    height: 35px;

    background: #D9D9D9;
    border-radius: 7px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;

    color: #000000;

    margin-top: 50px;
    
    /* 모든 input이 정상적이면 색 바꾸기 */
    &.allValid{
        background : #FA7225;
        cursor: pointer;
    }
`;

export const PaymentInfoMessageBox_Post = styled.div`
    width: fit-content;
    height : fit-content;

    
    margin-top: 10px;

`;

export const PaymentInfoMessageBox_Map = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: fit-content;
    height : fit-content;

    
    margin-top: 10px;

`;

export const PaymentInfoMessage = styled.div`
    width: fit-content;
    height : fit-content;
    color: #AAA;
    font-family: NanumSquare Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.625%;
    
    margin-top : 3px;
`;

export const TermsConditonBtn = styled.div`
    width: 85px;
    height: 30px;

    border-radius: 5px;
    border: 1px solid #BBB;
    background: #ECECEC;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);

    color: #5C5C5C;
    text-align: center;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%; /* 23.906px */

    cursor: pointer;

    margin-left: 20px;
`
export const ShippingWaitingTime = styled.div`
    display: flex;
    align-items: center;    
    justify-content: center;

    width : fit-content;
    height : 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;

    color: #36A60F;

`
