import styled from 'styled-components'

export const OfficeInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width : 100%;
    max-width: 480px;
    min-width: 300px;
    height : 110px;

    background: #F2F2F2;
    border: 2px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 7px;

    color : #BBBBBB;


    margin-top : 10px;
    padding : 18px 7px;

    cursor: pointer;

    &.Clicked {
        border-color: black;
        color : #000000;
    }

    &.notAvailable {
        border-color: red;
    }

`;

export const OffiecNum = styled.div`

`;

export const OfficeNameAndAddress = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    width : fit-content;

    padding : 0px 5px;
`;

export const OfficeName = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
`;

export const OfficeAddress = styled.div`
    width: 180px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;

    margin-top : 10px;
`;

export const OfficeWaitTime = styled.div`
    display: flex;
    align-items: center;    
    justify-content: center;

    width : fit-content;
    height : 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;

    color: ${props =>  props.available ? 
                        props.clicked ?
                            "#36A60F"
                        :
                            ""
                    : ""};


`;