import React, {useState} from 'react'

import {TermsConditionsContainer} from './TermsConditionsElements'
const TermsConditions = () => {

  return (
    <TermsConditionsContainer>
      <h1>WERO 3D 프린팅 웹 서비스 이용약관</h1>

      {/* 이용약관 내용 */}
      <h2>제1장 총칙</h2>
      <h3>제1조 (목적)</h3>
      <p>
      이 약관은 WERO (이하 "회사"라 합니다)이 제공하는 3D 프린팅 웹 서비스(이하 "서비스"라 합니다)의 이용과 관련하여 회사와 이용자와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
      </p>

      <h3>제2조 (정의)</h3>
      <p>
        이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
      </p>
      <ol>
        <li>"이용자"란 회사의 서비스에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
        <li>"회원"이라 함은 회사에 개인정보를 제공하여 회원등록을 한 자로서, 회사의 정보를 지속적으로 제공받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.</li>
        <li>"비회원"이라 함은 회원에 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.</li>
        <li>"서비스"라 함은 회사가 제공하는 3D 프린팅 및 관련 서비스를 의미하며, 이는 회사의 웹사이트, 모바일 앱, API 등을 통해 제공됩니다. 이 서비스에는 사용자가 제공한 3D 디자인의 프린팅, 프린팅된 제품의 배송, 그 외 회사가 정한 추가 서비스가 포함됩니다.</li>
      </ol>
      <h3>제3조 (약관의 게시와 개정)</h3>
      <ol>
        <li>회사는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.</li>
        <li>회사는 필요한 경우 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
        <li>약관이 개정되는 경우, 회사는 개정약관의 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.</li>
        <li>회사는 약관의 변경이 필요한 경우 이를 이메일, 서비스 내 알림 등의 방법으로 회원에게 통지할 수 있습니다. 회원이 변경된 약관에 동의하지 않는 경우, 서비스 이용이 제한될 수 있습니다.</li>
      </ol>	
      <h2>제2장 서비스 이용계약</h2>
      <h3>제4조 (서비스 이용계약의 성립)</h3>
      <p>이용자가 회사가 정한 절차를 거쳐서 이용신청을 하고, 회사가 이러한 신청에 대하여 승낙함으로써 이용계약이 성립합니다.</p>

      <h3>제5조 (서비스의 제공 및 변경)</h3>
      <p>
      회사는 다음과 같은 서비스를 제공합니다:
      </p>
      <ol>
        <li>3D 프린팅 서비스</li>
        <li>3D 프린팅 프린팅 결과물 배송 서비스</li>
        <li>기타 회사가 정하는 서비스</li>
      </ol>
      <p>
      회사는 서비스의 특성, 기술적 사양, 시스템 등에 따라 서비스의 내용을 변경할 수 있습니다. 이 경우, 회사는 변경 내용과 적용 일자를 서비스 초기 화면에 공지합니다.
      </p>

      <h3>제6조 (서비스의 중단)</h3>
      <p>
      회사는 서비스의 안정적인 제공을 위해 정기적이거나 임시적인 시스템 점검을 실시할 수 있으며, 이러한 점검 시간 동안에는 서비스 제공이 일시적으로 중단될 수 있습니다.
      </p>

      <h2>제3장 환불 및 취소 규정</h2>
      <h3>제7조 (환불 규정)</h3>
      <ol>
        <li>이용자는 서비스 이용 후 불만족스러운 결과에 대해 환불을 요청할 수 있습니다. 단, 환불 요청은 서비스 이용 후 7일 이내에 제기되어야 합니다.</li>
        <li>환불 요청은 회사의 이메일을 통해 제기할 수 있습니다. 환불 요청 시 이용자는 이용한 서비스, 이용 일시, 이용자 정보, 환불 요청 사유 등을 명시해야 합니다.</li>
        <li>회사는 환불 요청을 접수한 후 7일 이내에 해당 요청에 대한 검토를 진행하고, 그 결과를 이용자에게 통보합니다. 환불은 원칙적으로 이용자가 결제한 방법과 동일한 방법으로 이루어집니다.</li>
      </ol>
      <h3>제8조 (취소 규정)</h3>
      <ol>
        <li>이용자는 서비스 이용 전에 언제든지 서비스 이용을 취소할 수 있습니다.</li>
        <li>제작이 시작된 이후에는 취소 및 환불이 불가능합니다.</li>
      </ol>
      <h2>제4장 기타</h2>
      <h3>제9조 (면책 조항)</h3>
      <ol>
        <li>회사는 천재지변, 불가항력적 사유 등으로 인해 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
        <li>
          회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.
          <ul>
            <li>이용자가 제공한 정보의 부정확성 또는 불완전성</li>
            <li>이용자의 계정 보안을 유지하지 못하는 경우</li>
            <li>이용자가 약관을 위반하는 행동을 하는 경우</li>
            <li>이용자가 제공한 3D 디자인이 법적인 문제를 일으키는 경우</li>
          </ul>
        </li>
        <li>회사는 이용자가 게시 또는 전송한 자료의 내용에 대해 어떠한 책임도 지지 않습니다.</li>
      </ol>
      <h3>제10조 (분쟁 해결)</h3>
      <ol>
        <li>이 약관에 관한 분쟁은 대한민국의 법률을 준거법으로 하며, 분쟁의 해결은 대한민국의 서울 남부지방법원을 관할 법원으로 합니다.</li>
        <li>회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</li>
      </ol>

      <h3>제11조 (약관의 해석)</h3>
      <ol>
        <li>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령 또는 상관례에 따릅니다.</li>
        <li>회사는 필요한 경우 특정 서비스에 적용될 사항(이하 '개별약관'이라 합니다)을 별도로 정하여 이를 서비스 초기 화면, 이메일 등의 방법으로 공지할 수 있습니다. 이 경우 개별약관은 이 약관과 함께 적용됩니다.</li>
        <li>이 약관은 서비스의 특성, 사용자의 필요성, 그리고 법적 요구사항을 반영하여 작성되었습니다. 이용 약관은 서비스 제공자와 사용자 간의 권리와 의무를 명확하게 규정하며, 양 당사자가 서비스를 이용하면서 발생할 수 있는 문제를 미리 예방하고 해결하는데 중요한 역할을 합니다. 따라서 사용자는 서비스를 이용하기 전에 이용 약관을 반드시 확인하고 이해해야 합니다.</li>
        <li>이용 약관은 시간이 지나면서 변경될 수 있으므로, 사용자는 정기적으로 약관을 확인하여 최신 정보를 파악하는 것이 좋습니다. 서비스 제공자는 약관 변경 시 사용자에게 알리지만, 사용자 스스로도 약관을 확인하는 습관을 가지는 것이 중요합니다.</li>
        <li>이용 약관에 동의하지 않는 경우, 사용자는 서비스를 이용하지 않거나 서비스 제공자에게 문의하여 약관에 대한 추가적인 설명을 요청할 수 있습니다. 이용 약관은 법적 효력을 가지므로, 약관에 동의하면서 서비스를 이용하는 것은 사용자의 책임입니다.</li>
      </ol>

      <h3>제12조 (개인정보 보호 및 데이터 보안)</h3>
      <ol>
        <li>서비스는 사용자의 개인 정보를 수집할 수 있습니다. 이는 이름, 전화번호, 이메일 주소, 회사 이름, 직함, 부서, 국가, 작업 위치 등의 연락처 및 계정 정보를 포함할 수 있습니다. 또한, 제품 및 서비스 구매 시 결제 정보, 서비스와의 커뮤니케이션을 통해 얻은 정보도 수집될 수 있습니다.</li>
        <li>수집된 개인 정보는 소비자보호에 관한 법률에 따라 계약 또는 청약철회, 대금결제 및 재화 등 공급에 관한 기록은 수집일로부터 5년간 보관되며 다음과 같은 목적으로 사용될 수 있습니다: 제품 및 서비스 제공, 거래 처리 및 요청 이행, 고객 계정 관리, 고객 지원 서비스 제공, 고객 정보 확인, 결제 처리 등. 또한, 제품 및 서비스에 대한 문의에 응답하거나, 고객 지원을 제공하거나, 제품 및 서비스와 관련된 청구를 처리하는 등의 목적으로 사용자와의 커뮤니케이션을 위해 사용될 수 있습니다.</li>
        <li>서비스는 필요한 경우 개인 정보를 공유할 수 있습니다. 이는 제품 및 서비스 제공, 법적 의무 이행, 분쟁 해결, 계약 이행, 고객 지원 등의 목적을 위해 필요할 때입니다.</li>
        <li>서비스는 사용자의 개인 정보를 안전하게 보호하기 위해 적절한 보안 조치를 취하고 있습니다. 이는 정보의 손실, 오용, 무단 접근, 공개, 변경, 파괴를 방지하기 위한 것입니다.</li>
        <li>서비스는 웹사이트 트래픽을 분석하고, 사용자의 선호도를 기억하고, 최적의 경험을 제공하기 위해 쿠키를 사용할 수 있습니다. 사용자의 쿠키 사용을 관리하거나 거부할 수 있습니다.</li>
        <li>이용자는 자신의 개인정보를 정확하게 제공해야 하며, 개인정보가 변경된 경우 이를 즉시 업데이트해야 합니다. 이용자가 제공한 부정확한 정보로 인해 발생하는 문제에 대해서 회사는 책임을 지지 않습니다.</li>
        <li>이용자는 언제든지 자신의 개인 정보에 대한 접근, 수정, 삭제를 요청할 수 있습니다. 이러한 요청은 회사의 고객 지원 또는 개인정보 관리자에게 이메일로 제출할 수 있습니다. 회사는 이용자의 요청을 받은 후 적절한 기간 내에 이를 처리하고, 처리 결과를 이용자에게 통보합니다.</li>
      </ol>

      <h3>제13조 (지적재산권)</h3>
      <ol>
        <li>이용자가 서비스를 통해 제출하는 3D 디자인에 대한 지적재산권은 원칙적으로 이용자에게 있습니다. 단, 이용자는 회사에게 해당 디자인을 서비스 제공을 위해 사용할 수 있는 라이선스를 부여합니다. 이 라이선스는 서비스 제공 기간 동안 유효하며, 전 세계적으로 적용되며, 비독점적이며, 재라이선스가 가능합니다. "서비스 제공 기간"이라는 표현은 사용자가 서비스를 이용하는 기간을 의미합니다. 이 기간은 사용자가 서비스에 가입한 날부터 회원 탈퇴를 요청한 날까지를 의미합니다.</li>
        <li>이용자는 자신이 제출한 디자인이 타인의 지적재산권을 침해하지 않음을 보장해야 합니다. 이용자가 제출한 디자인이 타인의 지적재산권을 침해하여 발생하는 모든 문제에 대해서 이용자가 책임을 집니다.</li>
      </ol>

      <h3>제14조 (서비스 이용 제한)</h3>
      <ol>
        <li>이용자는 서비스를 합법적이고, 적절하게 이용해야 합니다. 불법적인, 부적절한, 해로운 내용의 3D 디자인을 업로드하는 것은 금지되어 있습니다.</li>
        <li>회사는 이용자가 약관을 위반하거나, 서비스의 정상적인 운영을 방해하는 행위를 하는 경우, 해당 이용자의 서비스 이용을 제한하거나, 회원 자격을 박탈할 수 있습니다.</li>
      </ol>

      <h3>제15조 (주문 프로세스)</h3>
      <ol>
        <li>이용자가 STL 파일을 업로드</li>
        <li>디테일, 채움정도 사용자 값 선택</li>
        <li>견적 확인</li>
        <li>결제창으로 이동</li>
        <li>배송 유무, 개인정보, 배송지 등 입력</li>
        <li>결제</li>
        <li>프린팅 된 제품을 방문해서 수령하거나, 택배로 수령</li>
      </ol>

      <h3>제 16조 (제품 수령)</h3>
      <p>3D 프린터로 인쇄된 항목은 인쇄가 완료되고 픽업 가능한 상태가 된 후 7일 이내에 수령되지 않으면 회사의 소유가 됩니다. 항목은 주문을 제출한 개인 또는 그들이 지정한 대리인이 제공된 QR를 사용하여 수령해야 합니다.</p>

      <p>이 약관은 2023년 7월 4일부터 적용됩니다.</p>

    </TermsConditionsContainer>
  )
}

export default TermsConditions