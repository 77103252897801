import React, {useState} from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Footer from '../components/Footer'
import PaymentInfo from '../components/PaymentInfo/Index'
// import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'

const PaymentInfoPage = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  };
  return (
    <>
      <ScrollToTop />
      {/* <Sidebar isOpen = {isOpen} toggle = {toggle}/> */}
      <Navbar toggle = {toggle}/>
      <PaymentInfo file = {props.file}/>
      {/* <Footer /> */}
    </>
  )
}

export default PaymentInfoPage