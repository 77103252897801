export const AboutObjone = {
    id : 'about',
    lightBg : true,
    lightText : false,
    lightTextDesc : true,
    topline : '3D printing',
    headline : 'Use 3D Printer for Reasonable Price!',
    description : 'Get access our app that allows you to use 3d printer for reasonable price',
    buttonLabel : 'Get Strarted',
    imgStart : false,
    img : require('../../images/logo1-3.png'),
    alt : 'Logo1',
    dark : true,
    primary : true,
    darkText : true
}
