import React, {useEffect, useState, useRef} from 'react'
import {useDropzone} from 'react-dropzone'
import Modal from 'react-modal'
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";


import PrintinfoCheckBox from '../PrintinfoCheckBox'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FileDropContainer,
        FileDropWrapper,
        FileDropInput,
        FileDropDecription1,
        FileDropDecription2,
        ModalContainer,
        CloseModalBtn,
        BtnContainer,
        ModalBtnLink,
        FileInput
        
} from './FileDropZoneElements';
import PrintingInfoModal from '../Modal/PrintingInfoModal';
import LoadingGIF from '../LoadingAnimation/index'

const FileDropZone = (props) => {
    const TookDakBaseUrl = process.env.REACT_APP_API_BASE_URL ;

    const [LoadingGIFOpen, setLoadingGIFOpen] = useState(false)

    const [borderColor, setBorderColor] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const {
        getRootProps, 
        getInputProps,
        acceptedFiles,
        fileRejections

    } = useDropzone({
        accept : '.stl',

    });
    const [currnetfile, setCurrnetFile] = useState()
    const [currnetfilename, setCurrnetFilename] = useState()
    const [originfilename, setOriginfilename] = useState()
    const [fileUrl, setFileUrl] = useState("")
    const [fileProcessingDone, setFileProcessingDone] = useState(false);
    const history = useHistory();


    const CheckfileSize = (size) => {//나중에 조금 더 깔끔하게 고쳐보자
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        const parseSize = parseFloat((size / Math.pow(k, i)).toFixed(2));
        if(sizes[i] === sizes[3] || sizes[i] === sizes[4]){
            return 51;
        }else if(sizes[i] === sizes[0] || sizes[i] === sizes[1]){
            return 0;
        }else{
            return parseSize;
        }
    }

    const validateFile1 = (file) => {
       
        const fileName = file.name;
        const extensions = [".stl", ".STL"]; 

        const isExtensionMatch = extensions.some(ext => fileName.endsWith(ext));

        if (isExtensionMatch) {
            return true; 
          } else {
            return false; 
          }
    } 

    const handleFiles1 = (files) => {
        let parseSize = 0;
        for(let i = 0; i < files.length; i++) {
            if (validateFile1(files[i])) {
                
                parseSize = CheckfileSize(files[i].size);
                if(parseSize<80){//나중에는 51로 왜냐하면 파일 용량 제한 51mb라서
                    setSelectedFiles(prevArray => [...prevArray, files[i]]);
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        // ArrayBuffer를 Blob URL로 변환하고 상태를 업데이트합니다.
                        const arrayBuffer = event.target.result;
                        const blob = new Blob([arrayBuffer], { type: files[0].type });
                        const url = URL.createObjectURL(blob);
                        const date = new Date();
                        setCurrnetFile(files[i])
                        setOriginfilename(files[i].name)
                        sessionStorage.setItem("File_name", files[i].name.split(".")[0] +"_"+date.getFullYear()+(date.getMonth()+1)+date.getDate()+date.getTime()+".stl");
                        setFileUrl(url);
                        sessionStorage.setItem("File_Url",url);
                        setFileProcessingDone(true); // 파일 처리 완료를 알리는 상태를 설정합니다.
                      };
                    reader.readAsArrayBuffer(files[0]);
                    setLoadingGIFOpen(true)
                }else{
                    alert("file 용량 체크")
                    setFileProcessingDone(false)
                    break
                }
                
            } else {
                alert("STL 파일만 가능합니다.")
                setFileProcessingDone(false)
                break

            }
        }
    }
    useEffect(() => {
        if (fileProcessingDone) {
            console.log(LoadingGIFOpen)
            const stlformData = new FormData();
            stlformData.append('file', currnetfile, sessionStorage.getItem("File_name"));
            props.setFile(currnetfile)
            //paymentinfo에서 stl-upload하기 위해 props.setFile
            const Stlupload = async () =>{
                const data =  await axios.post(TookDakBaseUrl + "/stl-upload", stlformData);
                return data;
            }
            Stlupload().then(async (response) => {
                console.log(response)
                const stlUploadTaskId = response.data.task_id;
                        // Start polling for STL Analysis task
                while (true) {
                    const stlUploadTaskStatusResponse = await axios.get(TookDakBaseUrl + `/get-task-status?task_id=${stlUploadTaskId}`);
                    if (stlUploadTaskStatusResponse.data.task_status === "SUCCESS"){
                        setCurrnetFilename(stlUploadTaskStatusResponse.data.name);
                        console.log("stlUploadTaskStatusResponse");
                        console.log(stlUploadTaskStatusResponse);
                        break; // Stop polling
                    }else if(stlUploadTaskStatusResponse.data.task_status === "PENDING"){
                        sessionStorage.setItem("FileUploadQueue",stlUploadTaskStatusResponse.data)
                    }
                    else if(stlUploadTaskStatusResponse.data.task_status === "FAILURE"){
                        alert("STL 파일 분석에 실패했습니다.")
                        window.location = "/"
                        break
                    }
                    await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
                }
                
                setLoadingGIFOpen(false)
                setModalOpen(true)
                setFileProcessingDone(false); // 파일 처리 완료 상태를 초기화합니다.
            }).catch((error) => {
                console.error(error);
                setFileProcessingDone(false);
                setLoadingGIFOpen(false)
                alert("stl 파일 업로드에 실패했습니다.")
            });
        }
      }, [fileProcessingDone]);


    const dragOver = (e) => {
        e.preventDefault();
        setBorderColor(true);
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
        setBorderColor(false);
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        setBorderColor(false);
        

        if(files.length){
          handleFiles1(files);
        }
       
    }

    const fileInputRef = useRef();

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const filesSelected = () => { 
        if (fileInputRef.current.files.length) {
            handleFiles1(fileInputRef.current.files);
        }
    }
  
    return (
      <FileDropContainer >
        <FileDropWrapper 
            borderColor = {borderColor} {...getRootProps()}
            onDragOver = {dragOver}
            onDragEnter = {dragEnter}
            onDragLeave = {dragLeave}
            onDrop = {fileDrop}
            onClick={fileInputClicked}
        >
        
          <FileDropInput {...getInputProps()} />
          <FileDropDecription1>Only STL File</FileDropDecription1>
          <FileDropDecription2>Drag N drop some files here, or click to select files</FileDropDecription2>
          <FileInput
            ref={fileInputRef}
            className="file-input"
            type="file"
            onChange={filesSelected}
            />
        </FileDropWrapper>
        {LoadingGIFOpen && (
            <>
            <LoadingGIF

                size = {300}/>
            </>
        )}
        {modalOpen && (
                <PrintingInfoModal
                    CloseModal={() => {
                        setModalOpen(!modalOpen);
                    }}>
                    <PrintinfoCheckBox 
                        Uploadfilename = {currnetfilename}
                        originfilename = {originfilename}
                        fileUrl = {fileUrl}
                        file = {currnetfile}/>
                </PrintingInfoModal>
            )}
      </FileDropContainer>
    );
}

export default FileDropZone;