import styled from "styled-components";
import { Link } from 'react-router-dom';
import {Link as LinkR} from 'react-router-dom'

export const Container = styled.div`
    background: #F2F2F2;
    padding : 0px;
    min-height : 100vh;
    min-width: 1400px;
    position : relative;
    z-index : 1;
    margin-top : 70px;
    @media screen and (max-width : 768px){
        flex-direction: column;
        height: fit-content;
    }
`

export const MyPageContainer = styled.div`
    display : flex;
    flex-direction: column;

    width : 65%;
    min-height : 100vh;
    min-width: 1000px;

    margin : 0 auto;
    padding-top : 60px;


`
export const TitleWrapper = styled.div`
    width : 100%;
    height : fit-content;

    display: flex;
    flex-direction: row;

    justify-content: space-between;

    border-bottom: 1px solid #CCC;

    padding-bottom: 15px;
    padding-left : 10px;
    padding-right : 10px;

    @media screen and (max-width: 768px){
        font-size: 25px;
    }
`

export const Title= styled.div`
    width : 100%;
    height : fit-content;

    color: #000;
    /* Headline 1 */
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;

`

export const JobListContainer= styled.div`
    width : 100%;
    height : fit-content;

    display : flex;
    flex-direction: column; 

    margin-top: 40px;
    margin-bottom: 40px;
`

export const JobListWrapper= styled.div`
    width : 100%;
    height : fit-content;

    display : flex;
    flex-direction: column; 
`
export const JobItem = styled.div`
    width : 100%;
    height : fit-content;

    display : flex;
    flex-direction: row;
    
    justify-content: space-between;

    border-bottom: 2px solid #000;


    padding : 15px 10px;

    cursor: pointer;
`

export const JobFileName = styled.div`
    color: #000;

    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.625%; /* 32.344px */
`

export const JobOrderdateWrapper = styled.div`
    display: flex;
    flex-direction : row;
    gap : 30px;

    align-items: center;
`

export const JobOrderdate = styled.div`
    color: #B1B1B1;
    text-align: right;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.625%; /* 21.094px */
    white-space: nowrap;
`

export const JobProcessStatusImg = styled.img`
    position: relative;
    width: 136px;
    height: 33px;
    top: 0px;
`
export const IsItemExpanded = styled.img`
    position: relative;

    width: 20px;
    height: 20px;

    top : 0px;

`

export const JobItemContent = styled.div`
    display: flex;
    flex-direction : column;

    padding-top: 20px;
`

export const PrintingInfoRefresh = styled.img`
    position: relative;
    width: 120px;
    height: 25px;

    align-self: flex-end;
    cursor: pointer;

    transition: opacity 3s ease-in-out;
`
export const JobItemInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;

    min-width: 1000px;

    justify-content: space-between;
    padding: 0px 10px;

    


`

export const JobItemInfoSec1 = styled.div`
    display: flex;
    flex-direction : column;
`

export const BlurSnapShot = styled.div`
  position: relative;
  pointer-events: none;
  width: 350px;
  height: 180px;

  &.active{
    &::after {
        content: "대기중";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(150, 150, 150, 0.4);
        border-radius: 7px;

        display: flex;
        justify-content: center;
        align-items: center;
        color : rgba(242, 242, 242);
        font-weight: 900;
        font-size: 25px;

    }
}
`

export const PrintingSnapShot = styled.img`
    width: 350px;
    height: 180px;
    border-radius: 7px;

`
export const JobDetailInfoTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #CCC;

    margin-top : 25px;
    padding : 5px;

    cursor: pointer;
`

export const JobDetailInfoTitle = styled.div`
    color: #A8A8A8;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.625%; /* 23.906px */
    letter-spacing: -0.255px;
`
export const IsDetailInfoExpanded = styled.img`
    width: 15px;
    height: 15px;
`

export const JobDetailInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap : 15px;

    padding: 10px;

`

export const JobDetailInfoBox = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;

`

export const JobDetailInfoSubTitle = styled.div`
    width: 65px;
    height: 25px;
    flex-shrink: 0;
    color: #7B7B7B;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 140.625%; /* 22.5px */
    letter-spacing: -0.24px;
`

export const JobDetailInfo = styled.div`
    color: #7B7B7B;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%; /* 21.094px */
`

export const JobItemInfoSec2 = styled.div`
    display: flex;
    flex-direction : column;
`

export const JobItemInfoSec2Top = styled.div`
    height : 150px;
    width: 553px;
    border-radius: 10px 10px 0px 0px;
    background: #FFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);

    padding : 20px 28px;
`

export const JobItemInfoSec2TitleWrapper = styled.div`
    display: flex;
    flex-direction : row;
    justify-content: space-between;
`

export const JobItemInfoSec2Title = styled.div`
    display: flex;
    width : 125px;
    align-items: center;

    color: #000;

    /* Sub Title 2 */
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
    line-height: 140.625%; /* 25.313px */
    letter-spacing: -0.27px;

    margin-right : 63px;
`    

export const JobItemTimeInfoWrapper = styled.div`

`

export const JobItemStartTimeinfo = styled.div`
    color: #36A60F;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%; /* 19.688px */
    letter-spacing: -0.28px;
`


export const JobItemendTimeinfo = styled.div`
    color: #36A60F;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%; /* 19.688px */
    letter-spacing: -0.28px;
`

export const JobProgressBarWrapper = styled.div`
    margin-top: 20px;

`
export const JobProgressBar = styled.img`
    width : 495px;
    margin-bottom: 10px;
`

export const JobProgressStatusWrapper = styled.div`

display: flex;
flex-direction: row;
justify-content: space-between;

padding-left: 10px;

`

export const JobProcessWaiting = styled.div`
    color: #B0B0B0;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;

    &.active{
        color : #000000;
    }
`

export const JobProcessPrinting = styled.div`
    color: #B0B0B0;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    &.active{
        color : #000000;
    }

`

export const JobProcessEnd = styled.div`
    color: #B0B0B0;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    &.active{
        color : #000000;
    }

`

export const JobItemInfoSec2Bottom = styled.div`
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
    gap: 5px; 

    height : 280px;
    width: 553px;

    border-radius: 0px 0px 10px 10px;
    background: #FFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);

    margin-top: 15px;
    padding : 20px 28px;

`


export const PrintingTimeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid #CCC;

` 

export const PrintingTime = styled.div`
    display: flex;
    flex-direction: row;

    gap : 3px;

    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%; /* 22.5px */
` 
export const PrintingTimeHour = styled.div`

`

export const PrintingTimeMinute = styled.div`

`

export const PrintingTimeSecond = styled.div`

`

export const PrintingLocationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid #CCC;


`

export const PrintingLocationBox = styled.div`
    display: flex;
    flex-direction: column;
` 

export const PrintingLocationName = styled.div`
    color: #000;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.625%; /* 29.531px */
`

export const PrintingLocationAddress = styled.div`
    color: #000;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%; /* 21.094px */
`

export const ReceivemethodWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

` 

export const Receivemethod = styled.div`
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%; /* 22.5px */
` 