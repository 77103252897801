import React, {useEffect, useState} from 'react'
import {
    PrintinfoContainer,
    PrintinfoTitle,
    DividingLine,
    PrintInfoContentsWrraper,
    InfillInfoWrapper,
    InfillInfoTitleWrraper,
    InfillInfoTitle,
    QuestionMark,
    InfillBtnWrraper,
    InfillNormalBtn,
    InfillFullBtn,
    QualityInfoWrraper,
    QualityInfoTitleWrapper,
    QualityInfoTitle,
    QualityBtnWrraper,
    QualityDraftBtn,
    QualityNormalBtn,
    QualityDetailBtn,
    CheckBtnWrapper,
    CloseButton,
    ConfirmButton,
    InfillPopup,
    PQPopup
    
}from './CheckBoxElements'
import QuestionMarkUrl from '../../Asset/Icons/QuestionMark.png'
import { useHistory } from 'react-router-dom';
import axios from "axios";
import InfillPopUpUrl from "../../images/채움정도 팝업창.png"
import PQPopUpUrl from "../../images/프린트퀄리티 팝업창.png"
import LoadingGIF from '../LoadingAnimation/index'


const PrintinfoCheckBox = (props) => {
  const TookDakBaseUrl = process.env.REACT_APP_API_BASE_URL ;
  const history = useHistory()
  const [selectedInfill, setSelectedInfill] = useState(false);
  const [selectedQuality, setSelectedQuality] = useState("normal");
  const [LoadingGIFOpen, setLoadingGIFOpen] = useState(false)

  const [volume, setVolume] = useState("")
  const [width, setWidth] = useState("")
  const [length, setLength] = useState("")
  const [height, setHeight] = useState("")
  const [weight, setWeight] = useState("")
 
  const fileUrl = props.fileUrl
  console.log(fileUrl)
  const filename = props.Uploadfilename
  const currnetfile = props.file
  const originfilename = props.originfilename
  

  const onClose = props.CloseModal

  const [showInfillPopup, setShowInfillPopup] = useState(false);
  const [showPQPopup, setShowPQPopup] = useState(false);


  const onConfirm = async () => {
    // printsetting page로 printing 정보를 가지고 이동, stlupload api, stl to gcode api 호출 필요
    setLoadingGIFOpen(true)
    let intervalId = null;
    let analysisData = {}
    const stlformData = new FormData();
    const gcodeformData = new FormData();
    stlformData.append('file', currnetfile);
    console.log(currnetfile)
    console.log(stlformData)
    
    try {
        console.log(selectedInfill)
        gcodeformData.append('delicately', false);
        gcodeformData.append('infill', selectedInfill);
        gcodeformData.append('type', selectedQuality);
        gcodeformData.append('name', sessionStorage.getItem("File_name").split('.')[0]);

        // Call the STL Analysis API
        const API_Form_fileName= encodeURIComponent(sessionStorage.getItem("File_name").split('.')[0]);
        const stlAnalysisResponse = await axios.get(TookDakBaseUrl + `/get-analysis-stl?name=${API_Form_fileName}&infill=${selectedInfill}`);

        const stlTaskId = stlAnalysisResponse.data.task_id;

        // Start polling for STL Analysis task
        while (true) {
          const stlTaskStatusResponse = await axios.get(TookDakBaseUrl + `/get-task-status?task_id=${stlTaskId}`);
          console.log(stlTaskStatusResponse)
          if (stlTaskStatusResponse.data.task_status === "SUCCESS") {
            analysisData = {
              volume: Number(stlTaskStatusResponse.data["volume"]).toFixed(2),
              width: Number(stlTaskStatusResponse.data["width"]).toFixed(2),
              length: Number(stlTaskStatusResponse.data["length"]).toFixed(2),
              height: Number(stlTaskStatusResponse.data["height"]).toFixed(2),
              weight: Number(stlTaskStatusResponse.data["estimated_weight"]).toFixed(2)
            }
            sessionStorage.setItem("Material", "PLA")
            sessionStorage.setItem("Weight",analysisData.weight)
            sessionStorage.setItem("Volume",analysisData.volume)

            if(Math.min(analysisData.width, analysisData.length)*7  < analysisData.height){
              alert("설계작품의 가로(X) 혹은 세로(Y)와 높이(Z)의 비율이 1:7을 초과합니다. 안정적인 출력을 위해, 높이를 낮추거나 회전하여 재시도해주세요");
              window.location = "/"
              return
            }

            sessionStorage.setItem("Size_x",analysisData.width)
            sessionStorage.setItem("Size_y",analysisData.length)
            sessionStorage.setItem("Size_z",analysisData.height)
            


            if(!stlTaskStatusResponse.data.is_possible_to_print){
              for(var i = 0; i < stlTaskStatusResponse.data.reason.length; i++){
                alert(stlTaskStatusResponse.data.reason[i])
              }
              window.location = "/"
              return
            }
            console.log("SEAESFEAWRT")
            sessionStorage.setItem("Delicately",gcodeformData.get('delicately'));
            sessionStorage.setItem("Infill",gcodeformData.get('infill'));
            sessionStorage.setItem("Type", gcodeformData.get("type"))
            break; // Stop polling
          }else if(stlTaskStatusResponse.data.task_status === "FAILURE"){
            alert("STL 파일 분석에 실패했습니다. 다시 시도해주세요.")
            window.location = "/"
            return
          }
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
        }

        const gcodeResponse = await axios.post(TookDakBaseUrl + "/process-file-to-gcode", gcodeformData);
        const gcodeTaskId = gcodeResponse.data.task_id;

        while (true) {
          const gcodeTaskStatusResponse = await axios.get(TookDakBaseUrl + `/get-task-status?task_id=${gcodeTaskId}`);
          console.log(gcodeTaskStatusResponse);
          if (gcodeTaskStatusResponse.data.task_status === "SUCCESS") {
            setLoadingGIFOpen(false)
            if(gcodeTaskStatusResponse.data.task_result.result === "Success"){
              history.push({
                pathname: "/printsetting",
                state: {
                  filename: filename,
                  originfilename : originfilename,
                  fileUrl: fileUrl,
                  gcodeType : selectedQuality,
                  ...analysisData  // spread operator
                },
              });
            }else{
              alert("업로드한 파일을 Gcode 파일로 변환하지 못했습니다. 다시 시도해주세요.")
              window.location = "/"
            }

            break; // Stop polling
          }else if(gcodeTaskStatusResponse.data.task_status === "FAILURE"){
            alert("업로드한 파일을 Gcode 파일로 변환하지 못했습니다. 다시 시도해주세요.")
            window.location = "/"
          }
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
        }

    }catch (e) {
      console.log(e)
      alert(e.response+ "😭");
      window.location = "/"

    }
  }
  

  return (
    <>
    <PrintinfoContainer>
      <PrintinfoTitle>프린팅 옵션</PrintinfoTitle>
      <DividingLine/>
      <PrintInfoContentsWrraper>
        <InfillInfoWrapper>
          <InfillInfoTitleWrraper>
            <InfillInfoTitle>채움 정도 : </InfillInfoTitle>
            <QuestionMark
             src = {QuestionMarkUrl}
             onMouseEnter={() => setShowInfillPopup(true)}
             onMouseOut={() => setShowInfillPopup(false)} />
             {showInfillPopup && <InfillPopup src={InfillPopUpUrl} alt="채움정도 팝업" style={{position: "absolute", left:"100px", zIndex: 10}}/>}
          </InfillInfoTitleWrraper>
          <InfillBtnWrraper>
            <InfillNormalBtn 
              onClick={() => setSelectedInfill(false)}
              className={selectedInfill ? '' : 'active'}
              >
                보통
            </InfillNormalBtn>
            <InfillFullBtn 
              onClick={() => setSelectedInfill(true)}
              className={selectedInfill ? 'active' : ''}
              >
                꽉채움
            </InfillFullBtn>
          </InfillBtnWrraper>
        </InfillInfoWrapper>
        <QualityInfoWrraper>
          <QualityInfoTitleWrapper>
            <QualityInfoTitle>퀄리티 : </QualityInfoTitle>
            <QuestionMark 
            src = {QuestionMarkUrl}
            onMouseOver={() => setShowPQPopup(true)} 
            onMouseOut={() => setShowPQPopup(false)}/>
            {showPQPopup && <PQPopup src={PQPopUpUrl} alt="프린팅 퀄리티 팝업" style={{position: "absolute", left:"80px", zIndex: 10}}/>}
          </QualityInfoTitleWrapper>
          <QualityBtnWrraper>
            <QualityDraftBtn
              onClick={() => setSelectedQuality('draft')}
              className={selectedQuality === 'draft' ? 'active' : ''}>
                Draft
            </QualityDraftBtn>
            <QualityNormalBtn
              onClick={() => setSelectedQuality('normal')}
              className={selectedQuality === 'normal' ? 'active' : ''}>
                Normal
            </QualityNormalBtn>
            <QualityDetailBtn
              onClick={() => setSelectedQuality('detail')}
              className={selectedQuality === 'detail' ? 'active' : ''}>
                Detail
            </QualityDetailBtn>
          </QualityBtnWrraper>
        </QualityInfoWrraper>
      </PrintInfoContentsWrraper>
      <CheckBtnWrapper>
        <ConfirmButton onClick={onConfirm}>확인</ConfirmButton>
        <CloseButton onClick={onClose}>취소</CloseButton>
      </CheckBtnWrapper>
      {LoadingGIFOpen && (
            <LoadingGIF size = {300}/>
        )}
    </PrintinfoContainer>
    </>
  );
};

export default PrintinfoCheckBox