import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/index';
import SigninPage from './pages/signin'
import PrintSettingPage from './pages/printsetting';
import PaymentPage from './pages/payment';
import PaymentInfo from './pages/PaymentInfoPage';
import QnAPage from './pages/Q&A';
import PrintStart from './pages/printstart'
import Test from './pages/test'
import Terms_Conditions from './pages/TermsConditionsPage'
import Login from './pages/Login'
import MyPage from './pages/Mypage';
import AlertInMobileModal from './components/Modal/AlertInMobileModal'
import AlertinMobile from './components/AlertInMobile';


function App() {
  const [file, setFile] = useState(null);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const [modalOpen, setModalOpen] = useState(true);

  

  return (
    <>
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path = '/' exact={true} render={(props) => <Home {...props} setFile={setFile} />}/>
        <Route path = '/signin' component = {SigninPage} exact={true}/>
        <Route path = '/Q&A' component = {QnAPage} exact={true}/>
        <Route path = '/printsetting' component = {PrintSettingPage} exact={true}/>
        <Route path = '/payment' component={PaymentPage}/>
        <Route path = '/paymentinfo' render={(props) => <PaymentInfo {...props} file={file} />}/>
        <Route path = '/printstart' component={PrintStart}/>
        <Route path = '/test' component={Test}/>
        <Route path = '/terms_conditions' component={Terms_Conditions}/>
        <Route path = '/login' component={Login}/>
        <Route path = '/mypage' component={MyPage}/>
      </Switch>
    </Router>
    {isMobile && ( modalOpen && (
                <AlertInMobileModal
                    CloseModal={() => {
                        setModalOpen(!modalOpen);
                    }}>
                    <AlertinMobile/>
                </AlertInMobileModal>
            ))}
    </>
  );
}
// <Router basename={process.env.PUBLIC_URL}> -> 배포한 후 routing이 의도한 데로 되지않아 추가함
//참고 : https://stackoverflow.com/questions/42686149/cant-build-create-react-app-project-with-custom-public-url?rq=1 
export default App;