import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import { StlViewer } from 'react-stl-viewer'
import {tempurl} from "../Asset/Stlfiles/PikachuThick_tail.stl"

const PrintstartPage = () => {
    const style = {
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
      }

    const date = new Date();

    console.log(date.getTime())
    console.log(date.getFullYear())
    console.log(date.getMonth())
    console.log(date.getDate())
    console.log(date.getTime())


    
  return (
    <>
      <ScrollToTop />
      <StlViewer
            style={style}
            orbitControls
            shadows
            url={"PikachuThick_tail.stl"}
        />
    </>
  )
}

export default PrintstartPage