import React, {useCallback, useEffect, useState, useRef, forwardRef} from 'react'
import {
    Container,
    StilViewrWrapper,
   PrintingInfo,
   FileName,
   Context,
   PrintingInfoMessage,
   Smalltitle,
   Message,
   XYZ,
   MessageGroup,
   SizePopUp,
   DivingLine,
   SupportDescWrapper,
   QuestionMark,
   SupprtPopup,
   SupportDesc,
   PriceWrapper,
   Price,
   PaymentBtn
} from './PrintSettingElements'
import { StlViewer } from 'react-stl-viewer'
import ReactDOM from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';
import {GCodeViewer} from "react-gcode-viewer";
import PaymentPlease from "../../pages/PaymentInfoPage";
import axios from "axios";
import { FaVolumeDown } from 'react-icons/fa';
import QuestionMark_Support from '../../Asset/Icons/QuestionMark_Support.png'
import QuestionMarkUrl from '../../Asset/Icons/QuestionMark.png'

import SizePopUpUrl from "../../images/XYZ팝업 설명창.png"
import SupportPopUpUrl from "../../images/서포트 팝업창.png"
import html2canvas from "html2canvas"

const ForwardedStlViewer = forwardRef((props, ref) => {
  return <StlViewer ref={ref} {...props} />;
});



const PrintSetting = (props) => {
  const TookDakBaseUrl = process.env.REACT_APP_API_BASE_URL ;
  const [printingHour, setPrintingHour] = useState("")
  const [printingMinute, setPrintingMinute] = useState("")
  const [printingSecond, setPrintingSecond] = useState("")
  const [normalPrice, setNormalPrice] = useState("")


  const [showSupportPopup, setShowSupportPopup] = useState(false);


  const [studentPrice, setStudentPrice] = useState("")
  
  const [ispicUploadDone, setIspicUploadDone] = useState(false);
  const [isgetPriceDone, setIsgetPriceDone] = useState(false);

  const isAllValid = isgetPriceDone && ispicUploadDone;

  const style = {
    top: 0,
    left: 0,
    width: '900px',
    height: '676px'
  }

  const location  = useLocation();
  const history = useHistory()

  const API_Form_fileName= encodeURIComponent(sessionStorage.getItem("File_name").split('.')[0]);
  const originfilename = location.state?.originfilename
  const fileUrl = location.state?.fileUrl
  const gcodeType = location.state?.gcodeType

  const [showSizePopup, setShowSizePopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(TookDakBaseUrl + `/get-printing-time-and-price?name=${API_Form_fileName}`);
        const taskId = response.data.task_id;

        const intervalId = setInterval(async () => {
          const response = await axios.get(TookDakBaseUrl + `/get-task-status?task_id=${taskId}`);
          console.log(response);
          if (response.data.task_status === 'SUCCESS') {
            clearInterval(intervalId);
            saveAsImageHandler();
            setPrintingHour(response.data.task_result.hour);
            setPrintingMinute(response.data.task_result.minute);
            setPrintingSecond(response.data.task_result.second);
            setNormalPrice(Math.floor(response.data.task_result.normal_price));
            setStudentPrice(Math.floor(response.data.task_result.student_price));
            setIsgetPriceDone(true);
          } else if (response.data.task_status === 'FAILURE') {
            clearInterval(intervalId);
            alert("Gcode 파일 분석에 실패했습니다. 다시 시도해주세요")
            window.location.href = "/"
          }
        }, 1000);
      } catch (error) {
        alert(error);
      }
    };

    fetch(sessionStorage.getItem("File_Url"))
    .then(response => {
      if (response.ok) {
        console.log('Blob URL is valid');
        // 여기서 blob 데이터를 사용할 수 있습니다.
        fetchData(); // 페이지 로드 시 데이터 가져오기
      } else {
        console.error('Invalid Blob URL');
      }
    })
    .catch(error => {
      console.error('Error checking Blob URL:', error);
      window.alert("stl 파일 정보가 유실되었습니다. \n메인 페이지로 이동합니다.")
      return (history.push('/'));
    });

  }, [TookDakBaseUrl, API_Form_fileName]);


  const GoPayment = () => {
    if(isAllValid){
      sessionStorage.setItem("Hour",printingHour)
      sessionStorage.setItem("Minute",printingMinute)
      sessionStorage.setItem("Second",printingSecond)
      sessionStorage.setItem("NormalPrice",normalPrice)
      sessionStorage.setItem("StudentPrice",studentPrice)

      history.push({
        pathname: "/paymentinfo",
        state: {
          filename: originfilename,
          Price: normalPrice,
        },
      });
    }else{
      return 0;
    }
  }
  
  const saveAsImageHandler = async () => {
    const target = document.getElementById('StlViewer');
    if (!target) {
      return alert('결과 저장에 실패했습니다.');
    }
    html2canvas(target).then((canvas) => {
      canvas.toBlob(async (blob) => {
        if (!blob) {
          return alert('이미지 데이터를 가져오는 데 실패했습니다.');
        }
    
        // FormData를 생성하고 이미지를 첨부
        const formData = new FormData();
        formData.append('picture', blob, sessionStorage.getItem('File_name').split('.').slice(0, -1) +'.png');
        try {
          const pictureResponse = await axios.post(TookDakBaseUrl + "/picture-upload", formData,{
            headers: {
              'Content-Type': 'multipart/form-data', // 또는 서버에서 요구하는 형식으로 설정
            },
          });
          const pictureTaskId = pictureResponse.data.task_id;
          console.log(pictureResponse);

          while (true) {
            const pictureTaskStatusResponse = await axios.get(TookDakBaseUrl + `/get-task-status?task_id=${pictureTaskId}`);
            if (pictureTaskStatusResponse.data.task_status === "SUCCESS") {
              console.log("PICTURE UPLOAD SUCCESS");
              console.log(pictureTaskStatusResponse)
              sessionStorage.setItem("Pic_url",pictureTaskStatusResponse.data.image_url)
              setIspicUploadDone(true);
              break; // Stop polling
            }else if(pictureTaskStatusResponse.data.task_status === "FAILURE"){
              console.log("PICTURE UPLOAD FAIL")
            }
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
          }
    
        }catch(error){
          console.log(error);
          alert("STL 캡쳐 이미지 생성에 오류가 발생했습니다. 다시 시도해주세요.");
          window.location.href = "/"
        }
      }, 'image/png');
    });
  };

  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = '';

  }

  useEffect(()=>{
    (() => {
          window.addEventListener("beforeunload", preventClose);
          console.log(sessionStorage.getItem("File_Url"))

    })();   
    
    return () => {
          window.removeEventListener("beforeunload", preventClose);
        };
  },[]);


  return (
    <>
     <Container>
      <StilViewrWrapper>
        <StlViewer
              id='StlViewer'
              style={style}
              orbitControls
              shadows
              url= {sessionStorage.getItem("File_Url")}
              modelProps = {{
                positionX : 150,
                positionY : 150
              }}
              floorProps = {{
                gridWidth : 300,
                gridLength : 300
              }}
              showAxes = {true}
        />
      </StilViewrWrapper>
        <PrintingInfo>
          <PrintingInfoMessage>
              <FileName>
                {originfilename}
              </FileName>
            <MessageGroup>
              <Context>
                <Smalltitle>재료</Smalltitle>
                <Message>{sessionStorage.getItem("Material")}</Message>
              </Context>

              <Context>
                <Smalltitle>부피</Smalltitle>
                <Message> {sessionStorage.getItem("Volume")}cm3</Message>
              </Context>
              <Context>
                <Smalltitle>무게</Smalltitle>
                <Message>{sessionStorage.getItem("Weight")}g</Message>
              </Context>
              
              <Context>
                <Smalltitle>크기</Smalltitle>
                <QuestionMark 
                  src = {QuestionMarkUrl}
                  style={{marginLeft : "5px"}}
                  onMouseOver={() => setShowSizePopup(true)} 
                  onMouseOut={() => setShowSizePopup(false)}/>
                  {showSizePopup && <SizePopUp src={SizePopUpUrl} alt="사이즈 팝업" style={{position: "absolute", right:"350px", zIndex: 10}}/>}
                <Message style={{marginLeft : "15px"}}><XYZ>X </XYZ>{sessionStorage.getItem("Size_x")}mm <XYZ>Y </XYZ>{sessionStorage.getItem("Size_y")}mm <XYZ>Z </XYZ>{sessionStorage.getItem("Size_z")}mm</Message>
              </Context>

              <Context>
                <Smalltitle>출력 소요 시간</Smalltitle>
                <Message>
                  {printingHour > 0 ? 
                    <span>{printingHour}시간 </span> 
                    :
                    <></>
                  }
                  {printingMinute > 0 ? 
                    <span>{printingMinute}분 </span> 
                    :
                    <></>
                  }
                  {printingSecond > 0 ? 
                    <span>{printingSecond}초</span> 
                    :
                    <></>
                  }
                </Message>
              </Context>
              <SupportDescWrapper>
                <QuestionMark
                    src = {QuestionMark_Support}
                    onMouseEnter={() => setShowSupportPopup(true)}
                    onMouseOut={() => setShowSupportPopup(false)} />
                    {showSupportPopup && <SupprtPopup src={SupportPopUpUrl} alt="채움정도 팝업" style={{position: "absolute", right:"410px", zIndex: 10}}/>}
                <SupportDesc><span>서포트</span>는 제거되지 않은 상태로 제공됩니다.</SupportDesc>
              </SupportDescWrapper>
              <DivingLine/>
              <PriceWrapper>
                <Price>{normalPrice.toLocaleString()} 원</Price>
              </PriceWrapper>
            </MessageGroup>
            <PaymentBtn 
              className={isAllValid ? "allValid" : ""}
              onClick={GoPayment}>PAYMENT</PaymentBtn>
          </PrintingInfoMessage>
        </PrintingInfo>
        
    </Container>
    </>
  )
}

export default PrintSetting