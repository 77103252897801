import styled from "styled-components";
import { Link } from 'react-router-dom';
import {Link as LinkR} from 'react-router-dom'

export const Container = styled.div`
    background: #F2F2F2;
    display: grid;
    grid-template-columns: 3fr 2fr;
    padding : 0px;
    height : 100vh;
    min-height: 700px;
    min-width: 1400px;
    position : relative;
    z-index : 30;
    margin-top : 80px;
    @media screen and (max-width : 768px){
        flex-direction: column;
        height: fit-content;
    }

`
export const StilViewrWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const FormWrap = styled.div`
    /* height : 100%;
    display : flex;
    flex-direction : column;
    justify-content : center;

    @media screen and (max-width : 480px){
        height : 80px;
    } */
`
export const PrintingInfo = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
`

export const PrintingInfoMessage= styled.div`
    position: relative;
    margin-bottom: 5%;
    display: flex;
    width : 400px;
    height: 100vh;

    flex-direction: column;
    white-space: nowrap;
    align-items: center;
    justify-content: center;

    /* @media screen and (max-width : 768px){
        width: 750px;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: baseline;
    } */

    
`

export const FileName = styled.div`
    width: 100%;
    height: 55px;

    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    /* or 56px */
    color: #000000;

    margin-bottom: 40px;
`;

export const MessageGroup = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    white-space: nowrap;
    align-items: center;
    justify-content: center;  
    
`
export const Context = styled.div`
    position: relative;
    display: flex;
    flex-direction : row;
    width : 100%;
    height : 50px;

    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: 6px;

    padding-left: 10px;
    margin-bottom : 10px;


`
export const Smalltitle = styled.div`
    position: relative;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 140.62%;
    /* or 45px */


    color: #000000;
`
export const Message = styled.div`
    position: relative;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140.62%;
    /* or 42px */


    color: #000000;
    margin-left : 40px;
`
export const XYZ = styled.span`
    font-family: 'Roboto';
    font-weight: 800;
`

export const DivingLine = styled.div`
    width : 100%;
    height : 1px;

    background-color: #CCCCCC;

    margin-top: 20px;
`

export const SupportDescWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    margin-top: 10px;

`
export const SizePopUp = styled.img`
    width: 445px;
    height: 320px;
`
export const SupprtPopup = styled.img`
    width: 445px;
    height: 315px;
`
export const SupportDesc = styled.div`
    color: #FA7225;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
`
export const QuestionMark = styled.img`
    width : 15px;
    height : 15px;

    margin-right : 5px;
`;

export const PriceWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    width : 100%;
    height : 50px;

    padding-right: 15px;

`
export const Price = styled.div`

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 140.62%;

    color: #303030;

`
export const PaymentBtn = styled.div`
    display : flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height : 55px;
    margin-top: 5%;
    border-radius : 10px;

    background: #D9D9D9;

    white-space : nowarp;
    padding: 10px 22px;
    color : #000000;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size : 22px;
    
    outline : none;
    border : none;

    transition: all 0.2s ease-in-out;
    text-decoration : none;

    &.allValid{
        background : #FA7225;
        cursor: pointer;
    }
`
