import styled from "styled-components";

export const TermsConditionsContainer = styled.div`
    position: relative;
    background: #fff;
    display: flex;
    flex-direction: column;
    
    width: 100%;
    max-height: 550px;

    overflow: auto;

    color : black;
    text-align: left;


    justify-content : left;
    align-items : left;
    padding : 30px 50px;
    z-index : 5;
    > h1{
        margin-bottom : 15px;
    }
    > h2{
        margin-top: 13px;
    }

    > h3{
        margin-top: 10px;
    }

    > ol, ul{
        padding-left: 50px;
    }
`