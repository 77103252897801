import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import PrintStart from '../components/PrintingScreen'

const PrintstartPage = () => {
  return (
    <>
      <ScrollToTop />
       <PrintStart />
    </>
  )
}

export default PrintstartPage