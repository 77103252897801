import React, {useState, useEffect} from 'react'
import axios from "axios";

import Video from '../../videos/video.gif'
import FileDropZone from '../FileDropZone'
import { 
    MainContainer, 
    MainBg, 
    VideoBg,
    MainContent,
    MainH1,
    MainP,
    TookDakSWVersionWrapper,
    TookDakSWVersion,
    MaindropWrapper,

   // Dropbtn

} from './MainElements'
const MainSection = ({setFile}) => {
    const TookDakBaseUrl = process.env.REACT_APP_API_BASE_URL

    const [swUpdateDate, setSwUpdateDate] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await axios.get(TookDakBaseUrl + `/get-sw-version-from-text-file`);
              const taskId = response.data.task_id;
      
              const intervalId = setInterval(async () => {
                const response = await axios.get(TookDakBaseUrl + `/get-task-status?task_id=${taskId}`);
                console.log(response);
                if (response.data.task_status === 'SUCCESS') {
                    clearInterval(intervalId);
                    setSwUpdateDate(response.data.sw_version)
                }else if (response.data.task_status === 'FAILURE') {
                    clearInterval(intervalId);
                    console.log(response);
                    alert("이메일 또는 전화번호를 확인해주세요.")
                  }
                }, 1000);
            } catch (error) {
            alert(error);
            }
        };
        console.log("IS REFRESHED INFO??")
        fetchData(); // 페이지 로드 시 데이터 가져오기
    }, [TookDakBaseUrl])
  return (
    <MainContainer id='home'>
        <MainBg>
            <VideoBg src={Video} type='video/gif'/>
        </MainBg>
        <MainContent>
            <MainP>
                {/* Reasonable, Effective, Convenient 3D Printing Service */}
                쉽고 빠른 3D Printing Service
            </MainP>
            <TookDakSWVersionWrapper>ZADU SoftWare Version Update : 
                <TookDakSWVersion>{swUpdateDate === "" ? "  Loading..." : swUpdateDate}</TookDakSWVersion>
            </TookDakSWVersionWrapper>

            <MaindropWrapper>
                <FileDropZone setFile = {setFile}/>
            </MaindropWrapper>
        </MainContent>
    </MainContainer>
  )
}

export default MainSection