import React,{useState, useCallback, useEffect} from 'react'
import axios from "axios";

import PlusIconUrl from '../../Asset/Icons/plus.png'
import MinusIconUrl from '../../Asset/Icons/minus.png'
import SmallMinusIconUrl from '../../Asset/Icons/smallMinusIcon.png'
import SmallPlusIconUrl from '../../Asset/Icons/smallPlusIcon.png'
import PrintingInfoRefreshUrl_normal from '../../images/정보새로고침_normal.png'
import PrintingInfoRefreshUrl_hover from '../../images/정보새로고침_hover.png'
import PrintingInfoRefreshUrl_click from '../../images/정보새로고침_click.png'


import waitingProcessImgSrc from '../../images/출력 대기중.png'
import printingProcessImgSrc from '../../images/출력 진행중.png'
import endProcessImgSrc from '../../images/출력 완료.png'

import PrintingWaitPic from "../../images/프린팅대기예시사진.png"

import JobProcessWaitSrc from '../../images/Jobprocess_wait.png'
import JobProcessPrintSrc from '../../images/Jobprocess_printing.png'
import JobProcessEndSrc from '../../images/Jobprocess_end.png'
import {
    Container,
    MyPageContainer,
    TitleWrapper,
    Title,
    JobListContainer,
    JobListWrapper,
    JobItem,
    JobItemContent,
    JobFileName,
    JobOrderdateWrapper,
    JobOrderdate,
    JobProcessStatusImg,
    IsItemExpanded,
    PrintingInfoRefresh,
    JobItemInfoWrapper,
    JobItemInfoSec1,
    BlurSnapShot,
    PrintingSnapShot,
    JobDetailInfoTitleWrapper,
    JobDetailInfoTitle,
    IsDetailInfoExpanded,
    JobDetailInfoWrapper,
    JobDetailInfoSubTitle,
    JobDetailInfoBox,
    JobDetailInfo,
    JobItemInfoSec2,
    JobItemInfoSec2Top,
    JobItemInfoSec2Bottom,
    JobItemInfoSec2TitleWrapper,
    JobItemInfoSec2Title,
    JobItemTimeInfoWrapper,
    JobItemStartTimeinfo,
    JobItemendTimeinfo,
    JobProgressBarWrapper,
    JobProgressBar,
    JobProgressStatusWrapper,
    JobProcessWaiting,
    JobProcessPrinting,
    JobProcessEnd,
    PrintingTimeWrapper,
    PrintingTime,
    PrintingTimeHour,
    PrintingTimeMinute,
    PrintingTimeSecond,
    PrintingLocationWrapper,
    PrintingLocationBox,
    PrintingLocationName,
    PrintingLocationAddress,
    ReceivemethodWrapper,
    Receivemethod

} from './MyPageElements'

const MyPage = () => {
    const TookDakBaseUrl = process.env.REACT_APP_API_BASE_URL

    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const [jobList, setJobList] = useState({})
    const [visibleItems, setVisibleItems] = useState(5);
    const [totalItems, setTotalItems] = useState([]); // 전체 아이템 목록

    const [expandedItem, setExpandedItem] = useState(0);
    const [expandedDetailinfo, setExpandedDetailinfo] = useState(0);

    const [printingInfoRefreshUrl, setprintingInfoRefreshUrl] = useState(PrintingInfoRefreshUrl_normal)
    
    const [printingInfoRefreshState, setprintingInfoRefreshSteat] = useState('normal');
    const [printingInfoRefreshEventState, setprintingInfoRefreshEventSteat] = useState();

    const [isRefreshInfo, setIsRefreshInfo] = useState(true)

    const handleItemClick = (index) => {
        setExpandedItem(index === expandedItem ? null : index);
        setExpandedDetailinfo(index === expandedDetailinfo ? null : index);
      };

    const handleDetailInfoClick = (index) => {
        setExpandedDetailinfo(index === expandedDetailinfo ? null : index);
      };

    const handleRefreahInfoClick = (e) => {
        setprintingInfoRefreshUrl(PrintingInfoRefreshUrl_click);
        setprintingInfoRefreshSteat('active');
        setTimeout(() => {setIsRefreshInfo(!isRefreshInfo)}, 1500);
    }

    const handleRefreahInfoHover = (e) => {
        setprintingInfoRefreshEventSteat(e._reactName)
        if(e._reactName === 'onMouseEnter'){
            setprintingInfoRefreshUrl(PrintingInfoRefreshUrl_hover)
        }else{
            if(printingInfoRefreshState === 'active'){
                setprintingInfoRefreshUrl(PrintingInfoRefreshUrl_click);
            }else{
                setprintingInfoRefreshUrl(PrintingInfoRefreshUrl_normal)
            }
        }
        
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await axios.get(TookDakBaseUrl + `/get-job-information?user_num=${sessionStorage.getItem("User_Num")}`);
              const taskId = response.data.task_id;
      
              const intervalId = setInterval(async () => {
                const response = await axios.get(TookDakBaseUrl + `/get-task-status?task_id=${taskId}`);
                console.log(response);
                if (response.data.task_status === 'SUCCESS') {
                    clearInterval(intervalId);
                    setJobList(response.data.job_list.reverse())
                }else if (response.data.task_status === 'FAILURE') {
                    clearInterval(intervalId);
                    console.log(response);
                    alert("이메일 또는 전화번호를 확인해주세요.")
                  }
                }, 1000);
            } catch (error) {
            alert(error);
            }
        };
        fetchData(); // 페이지 로드 시 데이터 가져오기
        setprintingInfoRefreshSteat('normal')
        console.log(printingInfoRefreshState)
        if(printingInfoRefreshEventState === 'onMouseEnter'){
            setprintingInfoRefreshUrl(PrintingInfoRefreshUrl_hover)
        }else{
            setprintingInfoRefreshUrl(PrintingInfoRefreshUrl_normal)
        }
    }, [TookDakBaseUrl, isRefreshInfo])
    
    const handleScroll = () => {
        // 스크롤 이벤트를 감지하여 새로운 아이템 로드
        if (
            document.documentElement.offsetHeight - 300 < window.innerHeight + document.documentElement.scrollTop &&
          document.documentElement.offsetHeight - 260 < window.innerHeight + document.documentElement.scrollTop
        ) {
          // 사용자가 스크롤을 페이지의 맨 아래로 내렸을 때
          setVisibleItems((prevVisibleItems) => prevVisibleItems + 5);
        }
      };

    useEffect(() => {
        // 스크롤 이벤트 리스너 등록
        window.addEventListener('scroll', handleScroll);
        return () => {
          // 컴포넌트 언마운트 시 이벤트 리스너 제거
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);



    console.log(jobList)
  return (
    <>
        <Container>
            <MyPageContainer>
                <TitleWrapper>
                    <Title>
                        Job Tracking
                    </Title>
                    <PrintingInfoRefresh 
                    src = {printingInfoRefreshUrl}
                    onClick={handleRefreahInfoClick}
                    onMouseEnter={handleRefreahInfoHover}
                    onMouseOut={handleRefreahInfoHover}/>
                </TitleWrapper>
                <JobListContainer>
                    {Array.isArray(jobList) ? (
                            jobList.slice(0,visibleItems).map((item, index) => (
                            <JobListWrapper key={index}>
                                <JobItem onClick={() => handleItemClick(index)}>
                                    <JobFileName>
                                        {item.file_name}
                                    </JobFileName>
                                    <JobOrderdateWrapper>
                                        <JobOrderdate>
                                            {item.status_timestamp}
                                        </JobOrderdate>
                                        <JobProcessStatusImg src = {
                                            item.process_status === 'wait' ? JobProcessWaitSrc : 
                                            item.process_status === 'printing' ? JobProcessPrintSrc : JobProcessEndSrc
                                        }/>
                                        <IsItemExpanded src = {expandedItem === index ? MinusIconUrl: PlusIconUrl}/>
                                    </JobOrderdateWrapper>
                                </JobItem>
                                {expandedItem === index && (
                                <JobItemContent>
                                    <JobItemInfoWrapper>
                                        <JobItemInfoSec1>
                                            <BlurSnapShot className = {item.process_status === 'wait' ? 'active' : ''}>
                                                <PrintingSnapShot src = {(item.process_status === 'wait') ? PrintingWaitPic : ((item.process_status === 'printing') ? item.cam_url : item.pic_url)}/>
                                            </BlurSnapShot>
                                            <JobDetailInfoTitleWrapper onClick={() => handleDetailInfoClick(index)}>
                                                <JobDetailInfoTitle>출력물 상세 정보 보기</JobDetailInfoTitle>
                                                <IsDetailInfoExpanded src = {expandedDetailinfo === index ? SmallMinusIconUrl: SmallPlusIconUrl}/>
                                            </JobDetailInfoTitleWrapper>
                                            {expandedDetailinfo === index && (
                                                <JobDetailInfoWrapper>
                                                    <JobDetailInfoBox>
                                                        <JobDetailInfoSubTitle>재료</JobDetailInfoSubTitle>
                                                        <JobDetailInfo>{item.material}</JobDetailInfo>
                                                    </JobDetailInfoBox>
                                                    <JobDetailInfoBox>
                                                        <JobDetailInfoSubTitle>부피</JobDetailInfoSubTitle>
                                                        <JobDetailInfo>{item.volume}cm3</JobDetailInfo>
                                                    </JobDetailInfoBox>
                                                    <JobDetailInfoBox>
                                                        <JobDetailInfoSubTitle>무게</JobDetailInfoSubTitle>
                                                        <JobDetailInfo>{item.weight}g</JobDetailInfo>
                                                    </JobDetailInfoBox>
                                                    <JobDetailInfoBox>
                                                        <JobDetailInfoSubTitle>크기</JobDetailInfoSubTitle>
                                                        <JobDetailInfo>{item.size_x} X {item.size_y} X {item.size_z}cm</JobDetailInfo>
                                                    </JobDetailInfoBox>
                                                </JobDetailInfoWrapper>
                                            )}
                                        </JobItemInfoSec1>
                                        <JobItemInfoSec2>
                                            <JobItemInfoSec2Top>
                                                <JobItemInfoSec2TitleWrapper>
                                                    <JobItemInfoSec2Title>출력 진행 상황</JobItemInfoSec2Title>
                                                    <JobItemTimeInfoWrapper>
                                                        {item.start_time ? (<JobItemStartTimeinfo>출력 시작 시간 : {item.start_time}</JobItemStartTimeinfo>) : (<></>)}
                                                        {item.end_time ? (<JobItemendTimeinfo>출력 완료 시간 : {item.end_time}</JobItemendTimeinfo>) : (<></>)}
                                                    </JobItemTimeInfoWrapper>
                                                </JobItemInfoSec2TitleWrapper>

                                                <JobProgressBarWrapper>
                                                    <JobProgressBar
                                                        src = {
                                                            item.process_status === 'wait' ? waitingProcessImgSrc : 
                                                            item.process_status === 'printing' ? printingProcessImgSrc : endProcessImgSrc
                                                        }/>
                                                    <JobProgressStatusWrapper>
                                                        <JobProcessWaiting
                                                            className={item.process_status === 'waiting' ? "active" : ""}>
                                                            대기 중
                                                        </JobProcessWaiting>
                                                        <JobProcessPrinting
                                                            className={item.process_status === 'printing' ? "active" : ""}>
                                                            출력 중
                                                        </JobProcessPrinting>
                                                        <JobProcessEnd
                                                            className={item.process_status === 'end' ? "active" : ""}>
                                                            출력 완료
                                                        </JobProcessEnd>
                                                    </JobProgressStatusWrapper>
                                                </JobProgressBarWrapper>
                                            </JobItemInfoSec2Top>
                                            <JobItemInfoSec2Bottom>
                                                    <PrintingTimeWrapper>
                                                        <JobItemInfoSec2Title>출력 소요 시간</JobItemInfoSec2Title>
                                                        <PrintingTime>
                                                            <PrintingTimeHour>{Math.floor(Number(item.during_sec) / 3600) > 0 ? Math.floor(Number(item.during_sec) / 3600)+'시간' : ""}</PrintingTimeHour>
                                                            <PrintingTimeMinute>{Math.floor((Number(item.during_sec)%3600)/60) > 0 ? Math.floor((Number(item.during_sec)%3600)/60)+'분' : ""}</PrintingTimeMinute>
                                                            <PrintingTimeSecond>{Math.floor((Number(item.during_sec)%3600)%60) > 0 ? Math.floor((Number(item.during_sec)%3600)%60)+'초' : ""}</PrintingTimeSecond>
                                                        </PrintingTime>
                                                    </PrintingTimeWrapper>
                                                    <PrintingLocationWrapper>
                                                        <JobItemInfoSec2Title>출력 위치</JobItemInfoSec2Title>
                                                        <PrintingLocationBox>
                                                            <PrintingLocationName>TookDak {item.office_name}</PrintingLocationName>
                                                            <PrintingLocationAddress>{item.office_address}</PrintingLocationAddress>
                                                        </PrintingLocationBox>
                                                    </PrintingLocationWrapper>
                                                    <ReceivemethodWrapper>
                                                        <JobItemInfoSec2Title>수령 방법</JobItemInfoSec2Title>
                                                        <Receivemethod>{item.is_shipping ? "배송": "지점방문"}</Receivemethod>
                                                    </ReceivemethodWrapper>
                                            </JobItemInfoSec2Bottom>
                                        </JobItemInfoSec2>
                                    </JobItemInfoWrapper>
                                    
                                </JobItemContent>
                                )}
                            </JobListWrapper>
                            ))
                        ) : (
                            <></>
                        )}
                </JobListContainer>
            </MyPageContainer>
        </Container>
    </>
  )
}

export default MyPage