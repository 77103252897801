// export const homeObjone = {
//     id : 'about',
//     lightBg : true,
//     lightText : false,
//     lightTextDesc : true,
//     topline : '3D printing',
//     headline : 'Use 3D Printer for Reasonable Price!',
//     description : 'Get access our app that allows you to use 3d printer for reasonable price',
//     buttonLabel : 'Get Strared',
//     imgStart : false,
//     img : require('../../images/logo1-3.png'),
//     alt : 'Logo1',
//     dark : true,
//     primary : true,
//     darkText : true
// }
//imgStart : false => image right
//imgStart : true => image left
export const QnAObjone = {
    id : 'Q&A',
    lightBg : true,
    lightText : false,
    lightTextDesc : true,
    topline : '3D printing',
    headline : 'Use 3D Printer for Reasonable Price!',
    description : 'Get access our app that allows you to use 3d printer for reasonable price',
    buttonLabel : 'Get Strarted',
    imgStart : false,
    // img : require('../../images/logo1-1.png'),
    // alt : 'Logo1',
    dark : true,
    primary : true,
    darkText : true,
    img1 : require('../../images/beforeCatbusBack.png'),
    alt1 : 'example1',

    img2 : require('../../images/beforeCatbusFront.png'),
    alt2 : 'example2',

    img3 : require('../../images/beforeCatbusLeft.png'),
    alt3 : 'example3',

    img4 : require('../../images/beforeCatbusRight.png'),
    alt4 : 'example4',

    img5 : require('../../images/afterCatbusBack.png'),
    alt5 : 'example5',

    img6 : require('../../images/afterCatbusFront.png'),
    alt6 : 'example6',

    img7 : require('../../images/afterCatbusLeft.png'),
    alt7 : 'example7',
    
    img8 : require('../../images/afterCatbusRight.png'),
    alt8 : 'example8',
}
