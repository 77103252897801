import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import TermsConditions from '../components/TermsConditions'

const Terms_ConditionsPage = () => {
  return (
    <>
      <ScrollToTop />
      <TermsConditions />
    </>
  )
}

export default Terms_ConditionsPage