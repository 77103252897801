import styled from "styled-components";

export const AlertinMobileContainer = styled.div`
    position: absolute;
    width : 100%;
    height : 100%;
    display : flex;
    flex-direction : column;
    align-items: center;

    padding : 20px 15px 0px;

`;

export const AlertinMobileWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    padding-top : 30px;

`;

export const AlertinMobileMessage = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 13px;
`;

export const CheckBtnWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    width: 80%;
    height : fit-content;

    margin-top: 35px;
`;

export const ShareButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    position: relative;

    width: 100px;
    height: 30px;

    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    color : #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 23px;
    text-align: center;

    cursor: pointer;
`;

export const CloseButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    position: relative;

    width: 100px;
    height: 30px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    color : #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 23px;
    text-align: center;

    cursor: pointer;

`;