import styled from "styled-components";

export const PrintinfoContainer = styled.div`
    position: absolute;
    width : 100%;
    height : 100%;
    display : flex;
    flex-direction : column;

    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
`;

export const PrintinfoTitle = styled.div`
    position: relative;
    width: 110px;
    height: 23px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    text-align: center;

    color: #000000;
`;


export const DividingLine = styled.div`
    width : 100%;
    height : 1px;

    background-color: #CCCCCC;

    margin-top: 10px;
`;

export const PrintInfoContentsWrraper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    padding-left: 10px;
    padding-top : 40px;

`;

export const InfillInfoWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const InfillInfoTitleWrraper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const InfillInfoTitle = styled.div`
    position: relative;

    width : 75px;
    height : 25px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 23px;
    text-align: center;

    color: #000000;
`;

export const InfillPopup = styled.img`
    width : 305px;
    height : 148px;
`

export const PQPopup = styled.img`
    width : 475px;
    height : 350px;
`

export const QuestionMark = styled.img`
    width : 15px;
    height : 15px;

    margin-left : 5px;
`;

export const InfillBtnWrraper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height : fit-content;

    margin-top: 10px;

`;

export const InfillNormalBtn = styled.div`
   box-sizing: border-box;

    position: relative;
    align-items: center;

    width: 260px;
    height: 30px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    color : #BBBBBB;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 23px;
    text-align: center;

    cursor: pointer;

    &.active{
        border: 1px solid #000000;
        color : #000000;
    }
`;

export const InfillFullBtn = styled.div`

    box-sizing: border-box;

    position: relative;
    align-items: center;

    width: 260px;
    height: 30px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    color : #BBBBBB;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 23px;
    text-align: center;

    cursor: pointer;

    &.active{
        border: 1px solid #000000;
        color : #000000;
    }

`;

export const QualityInfoWrraper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const QualityInfoTitleWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 30px;
`;

export const QualityInfoTitle = styled.div`
    position: relative;

    width : 55px;
    height : 25px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 23px;
    text-align: center;

    color: #000000;
`;

export const QualityBtnWrraper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height : fit-content;

    margin-top: 10px;
`;

export const QualityDraftBtn = styled.div`
    box-sizing: border-box;

    position: relative;
    align-items: center;

    width: 170px;
    height: 30px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    color : #BBBBBB;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 23px;
    text-align: center;

    cursor: pointer;

    &.active{
        border: 1px solid #000000;
        color : #000000;
    }
`;

export const QualityNormalBtn = styled.div`
    box-sizing: border-box;

    position: relative;
    align-items: center;

    width: 170px;
    height: 30px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    color : #BBBBBB;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 23px;
    text-align: center;

    cursor: pointer;

    &.active{
        border: 1px solid #000000;
        color : #000000;
    }
`;

export const QualityDetailBtn = styled.div`
    box-sizing: border-box;

    position: relative;
    align-items: center;

    width: 170px;
    height: 30px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    color : #BBBBBB;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 23px;
    text-align: center;

    cursor: pointer;

    &.active{
        border: 1px solid #000000;
        color : #000000;
    }
`;

export const CheckBtnWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height : fit-content;

    margin-top: 50px;
`;

export const CloseButton = styled.div`
    box-sizing: border-box;

    position: relative;
    align-items: center;

    width: 260px;
    height: 30px;

    background: #F2F2F2;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    color : #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 23px;
    text-align: center;

    margin-left: 10px;

    cursor: pointer;

`;

export const ConfirmButton = styled.div`
    box-sizing: border-box;

    position: relative;
    align-items: center;

    width: 260px;
    height: 30px;

    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    color : #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 23px;
    text-align: center;

    margin-left: 10px;

    cursor: pointer;
`;