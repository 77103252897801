import React,{useState, useEffect, useCallback} from 'react'
import axios from "axios";

import {
    Container,
    LoginContainer,
    Title,
    InputForm,
    UserInputBox,
    BlurOverlay,
    UserInputTitle,
    UserInputwrapper,
    UserIdInput,
    UserPasswordInput,
    Loginbtn,
    NonUserInputBox,
    NonUserInputTitle,
    NonUserInputwrapper,
    NonUserEmailInput,
    NonUserPhoneNumberInput,
} from './LoginElements'

const LoginPage = () => {

    const TookDakBaseUrl = process.env.REACT_APP_API_BASE_URL

    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const [userPhoneNumErrMsg, setUserPhoneNumErrMsg] = useState("")
    const [userEmailErrMsg, setUserEmailErrMsg] = useState("")

    const validatePhoneNum = (phoneNum) => {
        const regex = /^\d{3}-?\d{3,4}-?\d{4}$/;
        return regex.test(phoneNum);
    };
    

    const onChangePhoneNum = useCallback((e) => {
        const inputValue = e.target.value;
        const currentPhoneNum = inputValue.replace(/[^0-9]/g, '');
        e.target.value = currentPhoneNum
        setPhoneNumber(currentPhoneNum)

        if(!validatePhoneNum(currentPhoneNum)){
            setUserPhoneNumErrMsg("올바른 전화번호를 입력해주세요.");
        }
    }, [])

    
    const validateEmail = (email) => {
        return email
          .toLowerCase()
          .match(
            /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
          );
      };
      
    const onChangeEmail =  useCallback((e) => {
        const currentEmail = e.target.value;
        setEmail(currentEmail)

        if(!validateEmail(currentEmail)){
            setUserEmailErrMsg("이메일 형식이 올바르지 않습니다.");
        }else{
            setUserEmailErrMsg("");
        }
    }, [])

    const isPhoneNumValid = validatePhoneNum(phoneNumber)
    const isEmailValid = validateEmail(email)

    
    const isAllValid = isPhoneNumValid && isEmailValid;

    const onClickLoginBtn = () => {
        if(isAllValid){
            const getUserNum=  async () =>{
                const response =  await axios.get(TookDakBaseUrl + `/get-user-num?email=${email}&phone_num=${phoneNumber}`);
                return response
            }
            getUserNum().then((response) => {
                console.log(response)
                sessionStorage.setItem("User_Num",response.data.user_num)
                window.location.href = "/mypage"
                //조회페이지 이동

            }).catch(async (error) => {
                console.error(error.response);
                alert("이메일 또는 전화번호를 확인해주세요.")
            });
        }else{
            return 0;
        }
    }

    const handleOnKeyDown = e => {
        if (e.key === 'Enter') {
            onClickLoginBtn(); // Enter 입력이 되면 클릭 이벤트 실행
        }
      };


  return (
    <>
        <Container>
            <LoginContainer>
                <Title>
                    주문 조회
                </Title>
                <InputForm>
                    <UserInputBox>
                        <BlurOverlay>추후 공개 예정</BlurOverlay>
                        <UserInputTitle>
                            로그인
                        </UserInputTitle>
                        <UserInputwrapper>
                            <UserIdInput
                                id = "id"
                                type = "text"
                                autoComplete='off'
                                placeholder='아이디'
                                onKeyDown={handleOnKeyDown}
                            >

                            </UserIdInput>
                            <UserPasswordInput
                                id = "password"
                                type = "text"
                                autoComplete='off'
                                placeholder='비밀번호'
                                onKeyDown={handleOnKeyDown}
                            >

                            </UserPasswordInput>
                        </UserInputwrapper>
                        <Loginbtn type='button'>
                            로그인
                        </Loginbtn>
                    </UserInputBox>

                    <NonUserInputBox>
                        <NonUserInputTitle>
                            비회원
                        </NonUserInputTitle>
                        <NonUserInputwrapper>
                            <NonUserEmailInput
                                id = "email"
                                type = "text"
                                autoComplete='off'
                                onChange = {onChangeEmail}
                                onKeyDown={handleOnKeyDown}
                                placeholder='이메일'/>
                            <NonUserPhoneNumberInput
                                id = "PhoneNumber"
                                type = "text"
                                onChange = {onChangePhoneNum}
                                onKeyDown={handleOnKeyDown}
                                autoComplete='off'
                                placeholder='핸드폰 번호'/>
                        </NonUserInputwrapper>
                        <Loginbtn 
                            type='button'
                            className={isAllValid ? "allValid" : ""}
                            onClick={onClickLoginBtn}>
                            조회하기
                        </Loginbtn>
                    </NonUserInputBox>

                </InputForm>                
            </LoginContainer>
        </Container>
    </>
  )
}

export default LoginPage