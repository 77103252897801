import React, {useState} from 'react'
import ScrollToTop from '../components/ScrollToTop'
import MyPageComponent from '../components/MyPage'
// import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


const MyPage = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
      setIsOpen(!isOpen)
    };
  return (
    <>
        {/* <Sidebar isOpen = {isOpen} toggle = {toggle}/> */}
        <Navbar toggle = {toggle}/>
        <MyPageComponent/>
        <ScrollToTop />
        {/* <Footer /> */}

    </>
  )
}

export default MyPage