import styled from "styled-components";
import { Link } from 'react-router-dom';
import {Link as LinkR} from 'react-router-dom'

export const Container = styled.div`
    background: #F2F2F2;
    padding : 0px;
    height : 100vh;
    min-width: 1400px;
    min-height: 500px;
    position : relative;
    z-index : 1;
    margin-top : 80px;
    @media screen and (max-width : 768px){
        flex-direction: column;
        height: fit-content;
    }
`

export const LoginContainer = styled.div`
    display : flex;
    flex-direction: column;

    width : 60%;
    height : 100vh;

    margin : 0 auto;
    padding-top : 70px;


`

export const Title= styled.div`
    width : 100%;
    height : fit-content;

    border-bottom: 1px solid #CCC;

    color: #000;
    /* Headline 1 */
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;

    padding-bottom: 15px;
    padding-left : 10px;

    @media screen and (max-width: 768px){
        font-size: 25px;
    }

`
export const InputForm= styled.div`
    display : grid;
    grid-template-columns: 1fr 1fr;

    > * { // 모든 직접적인 자식 요소에 대해
        border-right: 1px solid #CCC; // 오른쪽 테두리로 구분선 추가
    }

    > *:last-child { // 마지막 자식 요소에 대해
        border-right: none; // 오른쪽 테두리 제거
    }

    margin-top : 10vb;


`
export const BlurOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(242, 242, 242, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; // 다른 요소 위에 나타나게 함
    backdrop-filter: blur(5px); // 블러 효과

    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
`;

export const UserInputBox= styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    height: 100%;

    justify-content: center;
    align-items: center;

    padding-top: 6vb;
    padding-bottom : 6vb;

`
export const UserInputTitle= styled.div`
    width: fit-content;
    height: fit-content;

    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }

`
export const UserInputwrapper = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 100%;

    margin-top: 30px;
`;

export const UserIdInput= styled.input`
    width : 80%;
    height : 30px;

    border-radius: 7px;
    border: 1px solid #BBB;
    background: #F2F2F2;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);

    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding-left : 15px;

    ::placeholder{
        color: #BBBBBB;
        font-weight: 600;
    }
`
export const UserPasswordInput= styled.input`
    width : 80%;
    height : 30px;

    border-radius: 7px;
    border: 1px solid #BBB;
    background: #F2F2F2;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);

    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-top: 10px;
    padding-left : 15px;

    ::placeholder{
        color: #BBBBBB;
        font-weight: 600;
    }

`
export const Loginbtn= styled.div`
    display: flex;

    align-items: center;
    justify-content: center;
    width : 80%;
    height : 35px;

    border-radius: 7px;
    background: #D9D9D9;

    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-top: 20px;

    &.allValid{
        background : #FA7225;
        cursor: pointer;
    }
`
export const NonUserInputBox= styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    height: 100%;

    justify-content: center;
    align-items: center;

    padding-top: 6vb;
    padding-bottom : 6vb;
`
export const NonUserInputTitle= styled.div`
    width: fit-content;
    height: fit-content;

    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }
`
export const NonUserInputwrapper = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 100%;

    margin-top: 30px;
`;
export const NonUserEmailInput= styled.input`
    width : 60%;
    height : 30px;

    border-radius: 7px;
    border: 1px solid #BBB;
    background: #F2F2F2;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);

    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding-left : 15px;
    ::placeholder{
        color: #BBBBBB;
        font-weight: 600;
    }
`
export const NonUserPhoneNumberInput= styled.input`
    width : 60%;
    height : 30px;

    border-radius: 7px;
    border: 1px solid #BBB;
    background: #F2F2F2;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);

    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-top: 10px;
    padding-left : 15px;

    ::placeholder{
        color: #BBBBBB;
        font-weight: 600;
    }
`