import React, {useState} from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Login from '../components/Login'
// import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


const LoginPage = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
      setIsOpen(!isOpen)
    };
  return (
    <>
        {/* <Sidebar isOpen = {isOpen} toggle = {toggle}/> */}
        <Navbar toggle = {toggle}/>
        <Login/>
        <ScrollToTop />
        {/* <Footer /> */}

    </>
  )
}

export default LoginPage